import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

function DynamicTable({ headers, rows }) {
  const table = {
    title: "",
    subtitle: "",
    headers: headers,
    rows: rows,
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          {table.headers.map((header, index) => (
            <TableCell key={index} style={{ textAlign: 'left', backgroundColor: 'aliceblue' }}>
              {header.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {table.rows.map((row, index) => (
          <TableRow key={index}>
            {row.map((cell, cellIndex) => (
              <TableCell key={cellIndex}>
                {cell && cell.cellType === 'box' ? (
                  <div style={{ backgroundColor: cell.color, width: '20px', height: '20px' }}></div>
                ) : (
                  cell
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default DynamicTable;
