import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, Button, Box, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import moment from 'moment';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '100%',
    maxHeight: '100vh',
    backgroundColor: 'white',
    overflow: 'auto',
    padding: '20px',
    margin: '20px',
    p: 4,
    WebkitOverflowScrolling: 'touch',
    scrollbarWidth: 'thin',
    scrollbarColor: 'transparent transparent',
};

function ConfusionMatrix({ open, onClose, title, scanResult, scanID, subScanID, kernals, userDetail, address, scanDetails }) {
    const filteredArray = kernals && kernals.length > 0 && kernals.filter(obj => obj.scanID === scanID && obj.subScanID === subScanID);
    const classes = scanResult?.kernel_details?.classes
    const encodings = scanResult?.processed_image?.encodings
    let rows = [];
    let extraRows = []
    let accuracyRow = [];
    let fractionRow = [];
    let column = [];
    let accuracy;
    const middleRows = [];
    middleRows.push("CD");
    const lastRow = [];
    lastRow.push("Total");
    classes.forEach((key, index) => {
        let row = [];
        column.push(encodings[key]);
        row.push(encodings[key]);
        const filteredData = filteredArray && filteredArray.filter((item) => item.originalClassName === key);
        const uniqueCombinations = new Map();
        filteredData && filteredData.forEach((obj) => {
            const combination = `${obj.index}-${obj.originalClassName}`;
            if (!uniqueCombinations.has(combination) || obj.timeStamp > uniqueCombinations.get(combination).timeStamp) {
                uniqueCombinations.set(combination, obj);
            }
        });
        const filteredKernel = Array.from(uniqueCombinations.values()).filter(obj => obj.originalClassName !== obj.changedClassName);
        const filteredDataForCDs = Array.from(uniqueCombinations.values()).filter(obj => obj.originalClassName === key && obj.changedClassName === "Cant_Determine");
        if (filteredDataForCDs.length) {
            let cdCount = 0;
            filteredDataForCDs.map((item) => {
                if (item.originalClassName === key) {
                    cdCount = filteredDataForCDs ? filteredDataForCDs.length : 0
                }
            })
            middleRows.push(cdCount);
        } else {
            middleRows.push(0);
        }
        const predictions = scanResult?.kernel_details?.kernel_counts;
        const fraction = `${filteredKernel.length ? filteredKernel.length : 0}/${parseInt(predictions[key], 10)}`;
        fractionRow.push(fraction);
        const numerator = Number(parseInt(predictions[key], 10)) - (filteredKernel.length ? filteredKernel.length : 0);
        const denominator = Number(parseInt(predictions[key], 10));
        accuracy = denominator !== 0 ? ((numerator / denominator) * 100).toFixed(2) + "%" : "0%";
        if (accuracy) {
            accuracyRow.push(accuracy)
        }
        classes.forEach((predictedLabel, predictedIndex) => {
            const filteredDataForChnage = filteredKernel && filteredKernel.filter((item) => item.changedClassName === predictedLabel);
            let count = 0;
            const sub = parseInt(predictions[key], 10) - (filteredKernel ? filteredKernel.length : 0)
            if (key === predictedLabel) {
                let sum = parseInt(predictions[key], 10)
                lastRow.push(sum);
                count = sub || 0;
                row.push(count)
            } else if (filteredData) {
                count = filteredDataForChnage ? filteredDataForChnage.length : 0;
                row.push(Number(count));
            } else {
                row.push(0);
            }
        });
        rows.push(row);
    });
    extraRows.push(middleRows);
    extraRows.push(lastRow);

    let transposedRows = [];
    for (let i = 1; i < rows[0].length; i++) {
        let newRow = [];
        for (let j = 0; j < rows.length; j++) {
            newRow.push(rows[j][i]);
        }
        transposedRows.push(newRow);
    }

    const exportCSV = () => {
        let csvContent = '';

        // First Table Header
        const firstTableHeaderRow = [
            'User Name',
            'Email/Phone Number',
            'ScanID',
            'Sub ScanID',
            'Grain Type',
            'Machine',
            'Date',
            'Phone/Device Name',
            'Location',
        ];
        csvContent += firstTableHeaderRow.join(',') + '\n';

        // First Table Row
        const firstTableRow = [
            `${userDetail?.userInfo?.first_name} ${userDetail?.userInfo?.last_name}`,
            userDetail?.userInfo?.email && userDetail?.userInfo?.phone_number
                ? `${userDetail?.userInfo?.email} / ${userDetail?.userInfo?.phone_number}`
                : userDetail?.userInfo?.email || userDetail?.userInfo?.phone_number,
            scanID,
            subScanID,
            `${scanDetails?.crop_details?.crop} - ${scanDetails?.crop_details?.variety}`,
            `${scanDetails?.machine_details?.machine_name} (${scanDetails?.machine_details?.machine_code})`,
            `"${moment(scanDetails?.scan_details[0]?.scan_date).format('DD MMM, YYYY hh:mm A')}"`,
            `${scanDetails?.scan_result?.scan_result?.scan_metadata?.device_info}`,
            `"${address?.display_name}"`,
        ];
        csvContent += firstTableRow.join(',') + '\n';
        csvContent += '\n';
        csvContent += '\n';
        csvContent += ',';
        csvContent += column.map(label => `"${label}"`).join(',');
        csvContent += '\n';
        transposedRows.forEach((row, index) => {
            csvContent += `"${rows[index][0]}",`;
            const csvRow = row.map(cell => `"${cell}"`).join(',');
            csvContent += csvRow + '\n';
        });
        extraRows.forEach(row => {
            csvContent += row.map(cell => `"${cell}"`).join(',');
            csvContent += '\n';
        });
        csvContent += '\n';
        csvContent += '\n';
        csvContent += '\n';
        csvContent += 'Accuracy From Confusion Matrix\n';
        csvContent += ',';
        csvContent += column.map(label => `"${label}"`).join(',');
        csvContent += '\n';
        csvContent += ',';
        csvContent += fractionRow.map(cell => `="${cell}"`).join(',');
        csvContent += '\n';
        csvContent += ',';
        csvContent += accuracyRow.map(cell => `="${cell}"`).join(',');
        csvContent += '\n';

        // Creating and downloading the CSV file (from your previous code)
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `Confusion_Matrix_${scanID}.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box style={style}>
                <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                    <Typography variant="h6" component="div" id="modal-modal-title" style={{ marginLeft: '20px' }}>
                        {title}
                    </Typography>
                    <div style={{ marginLeft: 'auto' }}>
                        <Button variant="contained" onClick={exportCSV}>Export CSV</Button>
                        <IconButton aria-label="close" onClick={onClose} style={{ marginLeft: '45px' }}>
                            <ClearIcon />
                        </IconButton>
                    </div>
                </div>
                <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead style={{ backgroundColor: 'aliceblue', textAlign: "left" }}>
                                <TableRow>
                                    <TableCell>user Name</TableCell>
                                    <TableCell>Email/phoneNumber</TableCell>
                                    <TableCell>ScanID</TableCell>
                                    <TableCell>Sub ScanID</TableCell>
                                    <TableCell>Grain Type</TableCell>
                                    <TableCell>Machine</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Phone/Device Name</TableCell>
                                    <TableCell>Location</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                    <TableCell align="left">
                                        {`${userDetail?.userInfo?.first_name} ${userDetail?.userInfo?.last_name}`}
                                    </TableCell>
                                    <TableCell align="left">
                                        {userDetail?.userInfo?.email && userDetail?.userInfo?.phone_number ? (
                                            <span>
                                                {userDetail?.userInfo?.email} / {userDetail?.userInfo?.phone_number}
                                            </span>
                                        ) : (
                                            <span>
                                                {userDetail?.userInfo?.email || userDetail?.userInfo?.phone_number}
                                            </span>
                                        )}
                                    </TableCell>
                                    <TableCell align="left">
                                        {scanID}
                                    </TableCell>
                                    <TableCell align="left">
                                        {subScanID}
                                    </TableCell>
                                    <TableCell align="left">
                                        {scanDetails?.crop_details?.crop} {scanDetails?.crop_details?.variety}
                                    </TableCell>
                                    <TableCell align="left">
                                        {scanDetails?.machine_details?.machine_name} ({scanDetails?.machine_details?.machine_code})
                                    </TableCell>
                                    <TableCell align="left">
                                        {moment(scanDetails?.scan_details[0]?.scan_date).format(('DD MMM, YYYY hh:mm A'))}
                                    </TableCell>
                                    <TableCell align="left">
                                        {scanDetails?.scan_result?.scan_result?.scan_metadata?.device_info}
                                    </TableCell>
                                    <TableCell align="left">
                                        {address?.display_name}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead style={{ backgroundColor: 'aliceblue', textAlign: "left" }}>
                                <TableRow>
                                    <TableCell />
                                    {column && column.map((trueLabel, trueIndex) => (
                                        <TableCell key={trueIndex}>{trueLabel}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transposedRows.map((row, index) => (
                                    <TableRow key={index} style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                        <TableCell>{rows[index][0]}</TableCell>
                                        {row.map((cell, cellIndex) => (
                                            <TableCell key={cellIndex} align="left" style={{ paddingRight: '50px' }}>
                                                {cell}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                                {extraRows.map((row, index) => (
                                    <TableRow key={index} style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                        {row.map((cell, cellIndex) => (
                                            <TableCell key={cellIndex} align="left" style={{ paddingRight: '50px' }}>
                                                {cell}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div> <h4>Accurcay From ConfusionMatrix</h4>
                <div style={{ marginTop: '20px' }}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead style={{ backgroundColor: 'aliceblue', textAlign: 'left' }}>
                                <TableRow>
                                    {column && column.map((trueLabel, trueIndex) => (
                                        <TableCell key={trueIndex}>{trueLabel}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>

                                    {fractionRow.map((cell, cellIndex) => (
                                        <TableCell key={cellIndex} align="left" style={{ paddingRight: '50px' }}>
                                            {cell}
                                        </TableCell>
                                    ))}
                                </TableRow>
                                <TableRow>
                                    {accuracyRow.map((cell, cellIndex) => (
                                        <TableCell key={cellIndex} align="left" style={{ paddingRight: '50px' }}>
                                            {cell}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Box>
        </Modal >
    );

}

export default ConfusionMatrix;
