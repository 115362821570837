import React, { useState } from 'react'
import { auth, provider } from './firebase'
import { signInWithPopup, RecaptchaVerifier, signInWithPhoneNumber, signInWithEmailAndPassword } from 'firebase/auth'
import { useNavigate } from 'react-router-dom';
import { TextField, CircularProgress, Button, Card, CardContent, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import upjao_logo from '../src/assests/images/Upjao_logo.png'
const url = window.config.endpoint;

function Login() {
  const [loading, setLoding] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [OTP, setOTP] = useState();
  const [expandForm, setExpandForm] = useState(false);
  const navigate = useNavigate();
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false)
  const [isValidOTP, setIsValidOTP] = useState(true);
  const [errorMessage, setErrorMessage] = useState('')
  const [error, setError] = useState(false)
  const [isGoogleButtonDisable, setIsGoogleButtonDisable] = useState(false)
  const [isPhoneButtonDisable, setIsPhoneButtonDisable] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [showInputs, setShowInputs] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClick = () => {
    setShowInputs(true);
    setIsGoogleButtonDisable(true)
    setIsPhoneButtonDisable(true)
  };
  const handleEmailnameChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const getuserRole = async (token) => {
    localStorage.setItem('token', token)
    if (token) {
      try {
        const [response, roleResponce] = await Promise.all([
          fetch(`${url}/users/get`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${token}`
            },
          }),
          fetch(`${url}/review/get_role`, {
            method: "GET",
            headers: {
              'Authorization': `Bearer ${token}`
            },
          })
        ])

        const userDetail = await response.json()
        const userRole = await roleResponce.json()

        console.log("responce", userDetail);
        console.log("roleResponce", userRole, userRole.status);

        if (userDetail.status) {
          localStorage.setItem("email", userDetail.data.userInfo.email)
          localStorage.setItem("phoneNumber", userDetail.data.userInfo.phone_number)
          localStorage.setItem('name', userDetail.data.userInfo.first_name)
          localStorage.setItem('userID', userDetail.data.userInfo.firebase_user_id)
        }
        if (userRole.status) {
          localStorage.setItem("role", userRole?.data?.userRole)
          navigate('/user')
        } else {
          setError(true);
          setErrorMessage('Unauthorized User');
          setExpandForm(false);
          setGoogleLoading(false);
          setLoding(false)
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  const signInwithGoogle = async () => {
    setGoogleLoading(true);
    setIsPhoneButtonDisable(true)
    await signInWithPopup(auth, provider).then(async (data) => {
      localStorage.setItem("email", data.user.email)
      localStorage.setItem('name', data.user.displayName)
      localStorage.setItem("photoURL", data.user.photoURL)
      const token = await auth.currentUser.getIdToken(true)
      token && getuserRole(token);
    }).catch((err) => {
      console.log(err);
      setGoogleLoading(false)
      setIsPhoneButtonDisable(false)
    })

  }

  // Sign in with mobile number
  const generateRecaptchaVerifier = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => { }
    }, auth);
  }

  const onSignInSubmit = (event) => {
    event.preventDefault();
    setLoding(!loading)
    generateRecaptchaVerifier();
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, '+91' + phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoding(false)
        // alert("6-digit OTP Sent on your mobile number.")
        setExpandForm(true);
      }).catch((err) => {
        console.error("error", err.code);
        if (err.code === 'auth/too-many-requests') {
          setErrorMessage('Too Many Attemps, Please try again later.')
        } else {
          setErrorMessage('An Error occur, Please try again later.')
        }
        setLoding(false)
      });

  }
  const onEmailorPasswordSubmit = (e) => {
    e.preventDefault()
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in 
        const user = userCredential.user;
        const token = await auth.currentUser.getIdToken(true)
        token && getuserRole(token);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });

  }
  const verifyOTPCode = (event) => {
    event.preventDefault();
    setLoding(!loading)
    if (OTP.length === 6) {
      window.confirmationResult.confirm(OTP).then(async (result) => {
        const user = result.user;
        // alert("otp verified");
        localStorage.setItem('phoneNumber', user.auth.currentUser.phoneNumber)
        localStorage.setItem('name', user.auth.currentUser.displayName)
        const token = await auth.currentUser.getIdToken(true)
        console.log('tokenPhone', token);
        token && getuserRole(token);
        setIsValidOTP(true)
      }).catch((err) => {
        console.error(err);
        setIsValidOTP(false)
        // alert("invalid otp")
      });
    }
  }

  // valid Mobile Number 
  const isValidPhone = (phoneNo) => {
    let pattern = /^[0-9]{10}$/;
    return pattern.test(phoneNo);
  }

  // handle phone input 
  const handlePhoneNumber = (event) => {
    if (event.target.value) {
      setErrorMessage('')
      setError(false)
      setIsGoogleButtonDisable(true)
    } else {
      setIsGoogleButtonDisable(false)
    }

    if (isValidPhone(event.target.value)) {
      setPhoneNumber(event.target.value);
      setIsValidPhoneNumber(true)
    } else {
      setIsValidPhoneNumber(false)
    }
  }

  // handle otp input
  const handleInputOTP = (event) => {
    if (event.target.value.length === 6) {
      setOTP(event.target.value)
      setIsValidOTP(true)
    } else {
      setIsValidOTP(false)
    }
  }
  return (
    <div>
      <div className='parent-container'>
        <form onSubmit={showInputs ? onEmailorPasswordSubmit : (expandForm ? verifyOTPCode : onSignInSubmit)}>
          <Card style={{ width: '400px', borderRadius: '10px' }}>
            <img
              style={{ height: '87px', width: '151px' }}
              className='m-20'
              alt="logo"
              src={upjao_logo}
            />
            <CardContent>
              {error &&
                <Alert severity="error" style={{ marginBottom: '15px' }}>{errorMessage}</Alert>}
              <div>
                <Button
                  className='w-100 App-bg-color button-padding'
                  variant="contained"
                  onClick={signInwithGoogle}
                  disabled={isGoogleButtonDisable}
                >
                  {!googleLoading ? <div><img alt='logo' style={{ position: 'relative', top: '4px' }} src="https://gitlab.com/assets/auth_buttons/google_64-9ab7462cd2115e11f80171018d8c39bd493fc375e83202fbb6d37a487ad01908.png" width={20} height={20} />
                    &nbsp; <span style={{ fontWeight: '500' }} id='loginWithGoogle'>SIGN IN WITH GOOGLE</span> </div> : <div id='googleLoadingSpinner'> &nbsp; <span style={{ position: 'relative', top: '4px', fontWeight: '500' }}><CircularProgress size={18} className='white' />&nbsp; </span>Loading...</div>}
                </Button>

                <h4 className='text-line gray'> OR </h4>
              </div>
              <div>
                <TextField
                  required
                  className='w-100'
                  id="outlined-required"
                  label="Phone Number"
                  type='number'
                  disabled={showInputs}
                  inputProps={{
                    maxLength: 10,
                  }}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10);
                  }}
                  onChange={(e) => handlePhoneNumber(e)}
                />
                {expandForm && <div>
                  <FormControl style={{ width: '100%', marginTop: '10px' }} variant="outlined">
                    <InputLabel >OTP</InputLabel>
                    <OutlinedInput
                      onKeyDown={
                        (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                      }
                      type={showPassword ? 'number' : 'password'}
                      onChange={(e) => handleInputOTP(e)}
                      required
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle otp visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="OTP"
                    />
                  </FormControl>
                </div>}
                {loading
                  ? <Button
                    className='w-100 m-t-10 App-bg-color button-padding'
                    id='loading'
                    variant="contained"
                  >
                    <span style={{ position: 'relative', top: '4px' }}><CircularProgress size={18} className='white' /> &nbsp;  </span><span> Loading...</span>
                  </Button>
                  : <Button
                    className='w-100 m-t-10 App-bg-color button-padding'
                    variant="contained"
                    type='submit'
                    id='verify-button'
                    disabled={isPhoneButtonDisable}
                  > {expandForm ? "VERIFY OTP" : "VERIFY PHONE"}
                  </Button>}
              </div>
              <div>
                <h4 className='text-line gray'> OR </h4>
                <Button
                  className='w-100 App-bg-color button-padding'
                  variant="contained"
                  type='submit'
                  id='verify-button'
                  disabled={isGoogleButtonDisable || isPhoneButtonDisable}
                  onClick={handleClick}
                >
                  SIGN IN WITH PASSWORD
                </Button>
                {showInputs && (
                  <div>
                    <TextField
                      label="Email"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={email}
                      onChange={handleEmailnameChange}
                    />
                    <TextField
                      label="Password"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      type="password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <Button
                      className='w-100 m-t-10 App-bg-color button-padding'
                      variant="contained"
                      type='submit'
                      id='verify-user-button'
                    >
                      VERIFY USER
                    </Button>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </form>
      </div>
      <div id='recaptcha-container'></div>
    </div>
  )
}

export default Login