import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import AccountMenu from './accountMenu'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import upjao_logo from '../../src/assests/images/Upjao_logo.png'

function Sidebar({ scanID, subScanID, isArrow }) {
  const drawerWidth = 200;
  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

  const [open, setOpen] = React.useState(true);

  const SidebarData = [
    {
      title: 'Scan',
      icon: <DocumentScannerOutlinedIcon />,
      link: '/user'
    },
    {
      title: 'Review History',
      icon: <ManageHistoryIcon />,
      link: '/reviewHistory'
    },
    {
      title: 'Scan Files',
      icon: <FileCopyOutlinedIcon />,
      link: '/scan-files'
    },
    {
      title: 'Android Log CSV',
      icon: <FileCopyOutlinedIcon />,
      link: '/csv'
    }
  ]

  const goBack = () => {
    window.history.back();
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar className='App-bg-color' position="fixed" open={open}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isArrow &&
              <ListItemIcon onClick={goBack}>
                <ArrowBackIcon sx={{ color: '#ffffff' }} />
              </ListItemIcon>
            }
            <Typography variant="h6" noWrap component="div">
              Review Portal
            </Typography>
          </div>
          <AccountMenu scanID={scanID} subScanID={subScanID} />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} >
        <DrawerHeader sx={{
          justifyContent: 'center',
        }}>
          <img
            style={{ height: '87px', width: '151px' }}
            alt="logo"
            className='m-20'
            src={upjao_logo}
          />
        </DrawerHeader>
        <List>
          {SidebarData.map((text, index) => (
            <ListItem key={text.title} disablePadding sx={{ display: 'block', background: window.location.pathname === text.link ? 'rgba(0, 0, 0, 0.04)' : "" }} onClick={() => { window.location.pathname = text.link }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  className='App-color'
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {text.icon}
                </ListItemIcon>
                <ListItemText primary={text.title} className='App-color' sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}

export default Sidebar