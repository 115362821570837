import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SortableTable from './sortableTable';
import { QRCodeCanvas } from 'qrcode.react';
import Button from '@mui/material/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '90vw',
    maxHeight: '90vh',
    backgroundColor: 'white',
    boxShadow: 24,
    p: 4,
};

function QrPopup({ title, open, onClose, rows, scanID, expandedColumns, selectedRowsForExpansion, isLoadingForChanges, setSelectedRowForExpansion, handleDownloadForExpansion, tabledata }) {
    const handleClearState = () => {
        if (setSelectedRowForExpansion) {
            setSelectedRowForExpansion([]);
        }
        onClose();
    };
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box style={{ ...style, overflowY: 'auto' }}>
                <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" component="div" id="modal-modal-title" style={{ marginLeft: '20px' }}>
                        {title}
                    </Typography>
                    {selectedRowsForExpansion && selectedRowsForExpansion.length > 0 && (
                        <Button variant="contained" className='App-bg-color' onClick={handleDownloadForExpansion} style={{ fontSize: '0.8rem', marginLeft: '20px' }}>Download JSON</Button>
                    )}
                    <div style={{ marginLeft: 'auto', marginRight: '20px' }}>
                        <IconButton aria-label="close" onClick={handleClearState} style={{ marginLeft: '20px' }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                {isLoadingForChanges ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    tabledata ? (
                        <div className="m-20">
                            <SortableTable rows={rows} columns={expandedColumns} />
                        </div>
                    ) : (
                        <div className="qr-code-wrapper">
                            <QRCodeCanvas
                                value={`https://link.upjao.ai/${scanID}`}
                                size={300}
                                bgColor={"#ffffff"}
                                fgColor={"#000000"}
                                level={"H"}
                                includeMargin={false}
                                imageSettings={{
                                    x: undefined,
                                    y: undefined,
                                    height: 24,
                                    width: 24,
                                    excavate: true,
                                }}
                            />
                        </div>
                    )
                )}
            </Box>
        </Modal>
    );
}

export default QrPopup;
