import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import moment from 'moment';
import ImageViewer from './imageViewer';
import { getCanvasImgData } from '../utils/api';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Checkbox from '@mui/material/Checkbox';
import { createReviewedKernal, getReviewedKernal } from '../utils/api'

const ImageComponent = ({
  index,
  imageUrl,
  kernelDetails,
  classNameCard,
  imageUrlForBottom,
  processData,
  scanDetails,
  scanId,
  userID,
  subScanId,
  handleCheckboxClick,
  boundingBox,
  selectedIndices,
  kernals,
  setKernals
}) => {
  const [changedClassName, setChangeClassName] = useState(classNameCard);
  const [open, setOpen] = useState(false);
  const [multipleBoxesUrl, setMultipleBoxesUrl] = useState('')
  const [multipleBoxesUrlForBottom, setMultipleBoxesUrlForBottom] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleCheckBox = async (className, index) => {
    await handleCheckboxClick(className, index)
  };

  const searchInLocalStorage = (index, originalClassName) => {
    let allChanges = kernals;
    if (allChanges) {
      const filteredChanges = allChanges.filter(
        (change) =>
          change.scanID === scanId &&
          change.subScanID === subScanId &&
          change.index === index &&
          change.originalClassName === originalClassName
      );

      if (filteredChanges.length === 0) {
        return {
          changed: false,
          finalObj: {}
        };
      } else {
        const latestTimestampObj = filteredChanges.reduce((prev, curr) => {
          return curr.timeStamp > prev.timeStamp ? curr : prev;
        });

        return {
          changed: true,
          finalObj: latestTimestampObj
        };
      }
    } else {
      return {
        changed: false,
        finalObj: {}
      };
    }
  };

  useEffect(() => {
    const { changed, finalObj } = searchInLocalStorage(index, classNameCard)
    if (changed) {
      setChangeClassName(finalObj.changedClassName);
    }
  }, [index, classNameCard, kernals])

  const handleButtonSelect = (className) => {
    setChangeClassName(className);
    handleChangeClassName(className, index, classNameCard);
  };

  const handleChangeClassName = async (newClassName, index, classNameCard) => {
    if (processData?.bboxes && processData?.bboxes.hasOwnProperty(classNameCard)) {
      const bboxes = processData.bboxes[classNameCard];
      if (bboxes && bboxes.length > index) {
        const [x, y, w, h] = bboxes[index];
        let reviewed_kernels = []
        const newObj = {
          originalClassName: classNameCard,
          changedClassName: newClassName,
          scanID: scanId,
          subScanID: subScanId,
          timeStamp: moment().valueOf(),
          index: index,
          X: x,
          Y: y,
          W: w,
          H: h,
          userID: userID,
        };
        reviewed_kernels.push(newObj)
        await createReviewedKernal(reviewed_kernels)
        const kernalsResponse = await getReviewedKernal(scanId, subScanId)
        setKernals(kernalsResponse?.data?.updatedReviewKernels)
      } else {
        console.error(`Invalid index ${index} for className ${newClassName}`);
      }
    }
  };

  const showBoundingBoxes = async (className, index) => {
    setOpen(true)
    setIsLoading(true)
    const capturedImageFileNames = ['captured.webp', 'captured.png', 'captured.jpeg'];
    const capturedImageFile = capturedImageFileNames.find((fileName) =>
      scanDetails?.scan_result?.scan_files.some((file) => file.file_name === fileName)
    );
    // const capturedImageUrl = capturedImageFile
    //   ? scanDetails?.scan_result?.scan_files.find((file) => file.file_name === capturedImageFile)?.file_url
    //   : null;
    const capturedImage = scanDetails?.scan_result?.scan_files?.find((file) => file.file_name === capturedImageFile)?.file_url
    const capturedImageUrl = capturedImage || scanDetails?.scan_result?.scan_files
      .find((file) => file.file_name.includes('_cv_server'))?.file_url || null;
    const capturedWebpFileForBottom = scanDetails && scanDetails?.scan_result?.scan_files.find(file => file.file_name === 'captured_bottom.webp');
    let capturedWebpUrlForBottom = capturedWebpFileForBottom?.file_url;
    if (!capturedWebpUrlForBottom) {
      const fileWithCvServer = scanDetails?.scan_result?.scan_files?.find(file => file.file_name.includes('_bottom_cv_server'));
      capturedWebpUrlForBottom = fileWithCvServer?.file_url || null;
    }
    const boundingBox = getBoundingBoxByClassName(className);
    const boundingBoxForBottom = getBoundingBoxForBottomByClassName(className);
    const selectedBoundingBox = boundingBox[index];
    const selectedBoundingBoxForBottom = boundingBoxForBottom && boundingBoxForBottom[index];
    let boxesUrl;
    let boxesUrlForBottom;
    if (selectedBoundingBox) {
      if (capturedImageUrl !== null) {
        boxesUrl = await getCanvasImgData(
          capturedImageUrl,
          JSON.stringify(selectedBoundingBox),
        )
      }
    }
    if (capturedWebpUrlForBottom !== null) {
      boxesUrlForBottom = await getCanvasImgData(
        capturedWebpUrlForBottom,
        JSON.stringify(selectedBoundingBoxForBottom),
      )
    }
    setMultipleBoxesUrlForBottom(boxesUrlForBottom)
    setMultipleBoxesUrl(boxesUrl)
    setIsLoading(false)
  }

  const getBoundingBoxByClassName = (className) => {
    if (processData?.bboxes && processData?.bboxes.hasOwnProperty(className)) {
      return processData?.bboxes[className];
    }
    return null;
  };
  const getBoundingBoxForBottomByClassName = (className) => {
    if (processData?.bboxes_bottom && processData?.bboxes_bottom.hasOwnProperty(className)) {
      return processData?.bboxes_bottom[className];
    }
    return null;
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          width: (imageUrlForBottom ? '400px' : '200px'),
          height: '200px',
          border: classNameCard === changedClassName ? '1px solid black' : '4px solid red',
          position: 'relative',
          display: (imageUrlForBottom ? 'flex' : '')
        }}
        key={index}
      >
        <div style={{ width: (imageUrlForBottom ? '50%' : '100%'), height: '100%' }}>
          <img src={imageUrl} alt={`Cropped ${index + 1}`} style={{ maxHeight: '80%', maxWidth: '80%' }} />
          {imageUrlForBottom && (
            <div style={{ fontSize: 14 }}>TOP</div>
          )}
        </div>
        {imageUrlForBottom && (
          <div style={{ width: '50%', height: '100%' }}>
            <img src={imageUrlForBottom} alt={`Cropped ${index + 1}`} style={{ maxHeight: '80%', maxWidth: '80%' }} />
            <div style={{ fontSize: 14 }}>BOTTOM</div>
          </div>
        )}
        <div className="icon-container" onClick={() => showBoundingBoxes(classNameCard, index)}>
          <ArrowForwardIcon />
        </div>
        <div className="checkbox-container" style={{ position: 'absolute', bottom: 0, right: 0 }}>
          <Checkbox checked={selectedIndices.includes(boundingBox[index])} onChange={() => handleCheckBox(classNameCard, index)} />
        </div>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: `repeat(${imageUrlForBottom ? 3 : 2}, 1fr)`, gap: '10px', padding: '20px' }}>
        {kernelDetails?.classes &&
          kernelDetails.classes.map((className, buttonIndex) => (
            <Button
              key={className}
              variant="outlined"
              style={{
                color: 'black',
                border:
                  changedClassName === className
                    ? '3px solid green'
                    : '1px solid #1976d2',
              }}
              onClick={() => handleButtonSelect(className)}
            >
              {` ${processData?.encodings[className]}`}
            </Button>
          ))}

        <Button variant="outlined"
          style={{
            color: 'black',
            border:
              changedClassName === 'Cant_Determine'
                ? '3px solid green'
                : '1px solid #1976d2',
          }}
          onClick={() => handleButtonSelect('Cant_Determine')}>
          CD
        </Button>
        {open && (
          <ImageViewer
            open={open}
            isLoading={isLoading}
            onClose={handleCloseModal}
            title="Image Viewer"
            imageUrl={multipleBoxesUrl}
            imageUrlForBottom={multipleBoxesUrlForBottom}
            imageAlt={'captured Image'}
            imageAltForBottom={'Bottom captured Image'}
          />
        )}
      </div>
    </div>
  );
};

export default ImageComponent;

