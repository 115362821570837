import React from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function Model({ title, open, onClose, data, scanID, subScanID, isLoading }) {
  const navigate = useNavigate();

  const handleButtonClick = (sub_scan) => {
    navigate(`/scan/${scanID}-${sub_scan}`);
    onClose()
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{ marginBottom: '18px' }}>
          <Typography variant="h6" component="div" id="modal-modal-title">
            {title}
          </Typography>
        </div>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          data && data.length > 0 ? (
            data.map((sub_scan, index) => (
              <Chip
                key={index}
                label={`Sub Scan ${sub_scan}`}
                color="success"
                onClick={() => handleButtonClick(sub_scan)}
                disabled={sub_scan === subScanID}
                style={{ marginRight: '8px', marginBottom: '8px' }}
              />
            ))
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body1">No Scans available.</Typography>
            </Box>
          )
        )}
      </Box>
    </Modal>
  );
}

export default Model;
