import React, { useEffect, useState } from "react";
import {
  AllDeepAnalysisDetails,
  UpdateDeepAnalysis,
  getScanResult, getScanFiles
} from "../utils/api";
import { Button, Card } from "@material-ui/core";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField
} from "@mui/material";
import AppConformationModel from "./AppConformationModel";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import ImageZoom from './zoomImage'

function DeepAnalysis() {
  const navigate = useNavigate();
  const [scanData, setScanData] = useState(null);
  const [scanFiles, setScanFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(true);
  const [errorMessage, setIsErrorMessage] = useState(false);
  const [updatedKarnelCount, setUpdatedKernalCount] = useState({});
  const [updatedKarnelWeight, setUpdatedKernalWeight] = useState({});
  const [isOpenConformationModel, setIsOpenConformationModel] = useState(false);
  const [overallScore, setOverallScore] = useState(0)
  const [updatedOverallScore, setUpdatedOverallScore] = useState(0)
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const path = window.location.pathname;
  const [inputValue, setInputValue] = useState('');
  const [showInputValue, showSetInputValue] = useState(false);
  const scan_id = path.split("/")[2];
  const sub_scan_id = path.split("/")[3];
  const [valuesArray, setValuesArray] = useState({});

  const fetchScans = async () => {
    try {
      setIsLoading(true);
      const path = window.location.pathname;
      const regex = /\/deep-analysis\/(\w+)\/(\d+)/;
      const match = path.match(regex);
      if (match && match.length === 3) {
        const scanID = match[1];
        const subScanID = parseInt(match[2], 10);
        const response = await getScanFiles(scanID, subScanID)
        const imageobject = {
          top: response?.topFileUrl,
          bottom: response?.bottomFileUrl
        };
        setScanFiles(imageobject)
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  const handleOkClick = () => {
    const splitValues = inputValue?.split(',').map(value => value.trim());
    const processedImageClasses = scanData?.kernel_details?.classes;
    if (processedImageClasses?.length < splitValues?.length) {
      alert('Please Enter Less values')
    }
    const resultObject = (processedImageClasses || []).reduce((obj, value, index) => {
      const className = value;
      const correspondingValue = splitValues[index];
      obj[className] = correspondingValue ? correspondingValue : 0;
      return obj;
    }, {});
    setValuesArray(resultObject);
  };
  useEffect(() => {
    fetchScans();
  }, [window.location.pathname]);

  const handleRefresh = () => {
    fetchScans();
  };

  useEffect(() => {
    const fetchScans = async () => {
      try {
        const response = await AllDeepAnalysisDetails(scan_id, sub_scan_id);
        setScanData(response?.data?.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchScans();
  }, []);

  useEffect(() => {
    const quality_score_dependencies_list = scanData?.kernel_details?.quality_score_dependencies
    const kernel_counts_object = scanData?.kernel_details?.kernel_counts

    let original_sum_score = 0;
    let updated_total_score = 0

    if (quality_score_dependencies_list) {
      for (const available_class of quality_score_dependencies_list) {
        const kernelCount = Number(kernel_counts_object[available_class]) || 0;
        const updatedKernelCount = Number(updatedKarnelCount[available_class]) || 0;
        original_sum_score += kernelCount;
        updated_total_score += updatedKernelCount;
      }
    }
    setOverallScore(original_sum_score)
    setUpdatedOverallScore(updated_total_score)
  }, [scanData, updatedKarnelCount])

  const onConfirmSubmit = () => {
    setIsOpenConformationModel(true);
    // const tota_kernal_count = scanData?.kernel_details?.total_kernel_count;
    const total_kernel_weight = scanData?.kernel_details?.total_kernel_weight;
    // const sumOfAllKernalCount = Object.values(updatedKarnelCount).reduce(
    //   (sum, value) => sum + parseFloat(value),
    //   0
    // );
    // const sumOfAllKernalWeight = Object.values(isupdatedKarnelWeightPercentage).reduce(
    //   (sum, value) => sum + parseFloat(value),
    //   0
    // );
    // const equalCount =
    //   Number(tota_kernal_count) === Number(Number(sumOfAllKernalCount).toFixed(2));
    const sumOfAllKernal = Object.values(updatedKarnelWeight).reduce(
      (sum, value) => sum + parseFloat(value),
      0
    );
    const fixedsumOfAllKernal = sumOfAllKernal.toFixed(2);
    const equalWeight =
      Number(total_kernel_weight) === Number(Number(fixedsumOfAllKernal));

    setTitle("Update Json");
    setMessage(
      // equalCount && equalWeight
      equalWeight
        ? "Do you want to Update ?"
        : "Eithe count or weight is not matched !!!"
    );
  };
  const navigateToDeepAnalysis = () => {
    navigate("/deep-analysis");
  }
  const updateModifiedJsonAPI = () => {
    if (updatedKarnelWeight || updatedKarnelCount) {
      scanData.kernel_details.kernel_counts = updatedKarnelCount;
      scanData.kernel_details.kernel_weights = updatedKarnelWeight;
    }
    message === "Do you want to Update ?" &&
      UpdateDeepAnalysis(scan_id, sub_scan_id, scanData, (res) => {

        if (res?.response?.data?.status === false) {
          setMessage(res?.response?.data?.message)
          setIsErrorMessage(true)
        } else {
          navigate("/deep-analysis");
        }
      });

  };

  return (
    <div style={{ overflowY: "auto", height: "100vh" }}>
      <div>
        <div>
          <div>
            <header
              style={{
                padding: 15,
                textAlign: "start",
                backgroundColor: "#b34d00",
                color: "white",
                position: "sticky",
                top: 0,
              }}
            >
              Deep Analysis - CV
            </header>

            <main>
              <Box>
                <h4>Deep Analysis Version 1</h4>
              </Box>

              <AppConformationModel
                open={isOpenConformationModel}
                setOpen={setIsOpenConformationModel}
                title={title}
                message={message}
                onActionButton={updateModifiedJsonAPI}
              />

              <div
                style={{
                  display: "flex",
                  gap: 40,
                  margin: 10,
                }}
              >
                <div style={{ width: "70%", margin: 16 }}>
                  <div>
                    {scanData && (
                      <Card
                        style={{
                          backgroundColor: "#E8F5E9",
                          borderRadius: "4px",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                          textAlign: "start",
                          paddingLeft: 10,
                        }}
                      >
                        {/* <p>
                          <span style={{ color: "#b34d00" }}>
                            <b>Crop:</b>{" "}
                          </span>
                          <span>{scanData?.crop_details?.crop}</span>
                        </p> */}
                        <div style={{ display: "flex", gap: "350px" }}>
                          <p>
                            <span style={{ color: "#b34d00" }}>
                              <b>Scan ID:</b>{" "}
                            </span>
                            <span>{scan_id}</span>
                          </p>
                          <p>
                            <span style={{ color: "#b34d00" }}>
                              <b>Sub Scan ID:</b>{" "}
                            </span>
                            <span>{sub_scan_id}</span>
                          </p>
                        </div>
                        {/* <p><span style={{ color: '#b34d00' }}><b>Total Kernal Count: </b></span><span>{scanData?.scan_result?.scan_result?.kernel_details?.total_kernel_count}</span></p>
                                            <p><span style={{ color: '#b34d00' }}><b>Manual Total Weight:</b> </span><span>{scanData?.scan_result?.scan_result?.kernel_details?.total_kernel_weight}</span></p> */}
                        {/* <p>
                          <span style={{ color: "#b34d00" }}>
                            <b>Total Kernal Count: </b>
                          </span>
                          <span> */}
                        {/* {console.log(
                              "not fetch,,,,,,",
                              scanData?.kernel_details?.total_kernel_count
                            )} */}
                        {/* {scanData?.kernel_details?.total_kernel_count}
                          </span> */}
                        {/* </p> */}
                        {/* <p>
                          <span style={{ color: "#b34d00" }}>
                            <b>Manual Total Weight:</b>{" "}
                          </span>
                          <span>
                            {scanData?.kernel_details?.total_kernel_weight}
                          </span>
                        </p> */}
                        {/* <p>
                          <span style={{ color: "#b34d00" }}>
                            <b>Overall Score:</b>{" "}
                          </span>
                          <span>
                            {overallScore}
                          </span>
                        </p>
                        <p>
                          <span style={{ color: "#b34d00" }}>
                            <b>Updated Overall Score:</b>{" "}
                          </span>
                          <span>
                            {updatedOverallScore}
                          </span>
                        </p> */}
                      </Card>
                    )}
                    <div style={{ display: 'flex', gap: '20px', alignItems: 'center', marginTop: "10px" }}>
                      <TextField
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder="Enter"
                        style={{ width: '300px' }}
                      />
                      <Button variant="contained" color='primary' onClick={handleOkClick}>OK</Button>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>
                        {scanData && (
                          <TableComponent
                            scanID={scan_id}
                            subScanID={sub_scan_id}
                            scanResult={scanData}
                            kernals={scanData?.kernel_details}
                            isEdit={isEdit}
                            setUpdatedKernalCount={setUpdatedKernalCount}
                            setUpdatedKernalWeight={setUpdatedKernalWeight}
                            valuesArray={valuesArray}
                          />
                        )}
                      </div>
                    </div>
                    <div>
                      <AppConformationModel
                        errorMessage={errorMessage}
                        open={errorMessage}
                        setOpen={setIsErrorMessage}
                        message={message}
                        onActionButton={navigateToDeepAnalysis}
                      />
                    </div>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          gap: 3,
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => setIsEdit(!isEdit)}
                        >
                          {isEdit ? "Back" : "Update"}
                        </Button>
                        {isEdit && (
                          <Button variant="contained" onClick={onConfirmSubmit}>
                            Submit
                          </Button>
                        )}
                        <Button variant="contained">Send Result</Button>
                        <Button variant="contained">Send Error</Button>
                      </Box>
                      <Box></Box>
                    </Box>
                  </div>
                </div>
                <div style={{ marginTop: '40px' }}>
                  {isLoading ? (
                    <CircularProgress />
                  ) : scanFiles && (
                    <>
                      <div>
                        <ImageZoom src={scanFiles.top} alt="Top Image" title="Top Image" />
                      </div>
                      <div>
                        <ImageZoom src={scanFiles.bottom} alt="Bottom Image" title="Bottom Image" />
                      </div>
                    </>
                  )}
                </div>
                <div><Button variant="contained" color='primary' onClick={handleRefresh}>Refresh</Button></div>
                {/* {imageList && <div style={{ width: '30%', display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                    <div>
                                        <p style={{ textAlign: 'left', marginLeft: 45 }}><b>Top View</b></p>
                                        <img src={imageList.top} alt="top-image" height="300" width="300" />
                                    </div>

                                    <div>
                                        <p style={{ textAlign: 'left', marginLeft: 45 }}><b>Bottom View</b></p>
                                        <img src={imageList.bottom} alt='bottom-image' height={300} width={300} />
                                    </div>
                                </div>} */}
              </div>
            </main>
          </div>
        </div>
      </div >
    </div >
  );
}

export default DeepAnalysis;

//  Table
export function TableComponent({
  scanResult,
  scanID,
  subScanID,
  kernals,
  isEdit,
  setUpdatedKernalCount,
  setUpdatedKernalWeight,
  valuesArray
}) {
  const [editKarnelCount, setEditKernalCount] = useState({});
  const [editKarnelCountPercent, setEditKernalCountPercent] = useState({});
  const [editKarnelWeight, setEditKernalWeight] = useState({});
  const [editKarnelWeightPercent, setEditKernalWeightPercent] = useState({});
  const [viewUpdatedValues, setViewUpdatedValues] = useState({
    updatedKarnelCount: 0,
    updatedKarnelCountPercentage: 0,
    updatedKarnelWeight: 0,
    updatedKarnelWeightPercentage: 0
  })

  useEffect(() => {
    setEditKernalWeightPercent(valuesArray);
  
    // Calculate the sum of all values in valuesArray
    const sum = Object.values(valuesArray)
      .map(value => parseFloat(value) || 0)
      .reduce((acc, value) => acc + value, 0);
  
    // Set the sum to the viewUpdatedValues state
    const fixedSum = sum.toFixed(2);
    setViewUpdatedValues({
      updatedKarnelWeightPercentage: fixedSum
    });
  
    for (const key in valuesArray) {
      const count_value = (
        (parseFloat(valuesArray[key]) || 0) * Number(scanResult?.kernel_details?.total_kernel_weight)/100
      ).toFixed(4);
  
      setUpdatedKernalWeight(prevState => ({
        ...prevState,
        [key]: count_value.toString()
      }));
    }
  }, [valuesArray, scanResult]);
  

  useEffect(() => {
    if (scanResult?.kernel_details?.kernel_counts) {
      setEditKernalCount({ ...scanResult?.kernel_details?.kernel_counts });
      setUpdatedKernalCount({ ...scanResult?.kernel_details?.kernel_counts });
      const kernel_counts_percentage = calculatePercentages(
        scanResult?.kernel_details?.kernel_counts,
        scanResult?.kernel_details?.total_kernel_count
      );
      setEditKernalCountPercent(kernel_counts_percentage);
    }

    if (scanResult?.kernel_details?.kernel_weights) {
      setEditKernalWeight({ ...scanResult?.kernel_details?.kernel_weights });
      setUpdatedKernalWeight({ ...scanResult?.kernel_details?.kernel_weights });
      const kernel_weight_percentage = calculatePercentages(
        scanResult?.kernel_details?.kernel_weights,
        scanResult?.kernel_details?.total_kernel_weight
      );
      setEditKernalWeightPercent(kernel_weight_percentage);
    }
  }, [scanResult, isEdit]);

  useEffect(() => {
    const sumOfAllKernalCount = Object.values(editKarnelCount).reduce(
      (sum, value) => sum + parseFloat(value),
      0
    );
    const sumOfAllKernalWeight = Object.values(editKarnelWeightPercent)
      .map(value => parseFloat(value) || 0)
      .reduce((sum, value) => sum + value, 0);

    const fixedSum = sumOfAllKernalWeight.toFixed(2);
    setViewUpdatedValues({
      updatedKarnelCount: sumOfAllKernalCount,
      updatedKarnelCountPercentage: (sumOfAllKernalCount * 100) / Number(scanResult?.kernel_details?.total_kernel_count),
      updatedKarnelWeight: fixedSum,
      updatedKarnelWeightPercentage: fixedSum
    });
  }, [editKarnelCount, editKarnelWeight])

  const calculatePercentages = (totalKernal, totalCount) => {
    const percentages = {};
    Object.keys(totalKernal).forEach((key) => {
      const count = parseFloat(totalKernal[key]);
      const percentage = ((count / totalCount) * 100).toFixed(2);
      percentages[key] = percentage;
    });
    return percentages;
  };
  const onHandleKernalCountAndPercenatage = (event, key) => {
    const tota_kernal_count = scanResult?.kernel_details?.total_kernel_count;
    if (event.target.name === "kernal_count") {
      setEditKernalCount((prevState) => ({
        ...prevState,
        [key]: event.target.value.toString(),
      }));

      setUpdatedKernalCount((prevState) => ({
        ...prevState,
        [key]: event.target.value.toString(),
      }));

      const percent = ((event.target.value * 100) / tota_kernal_count).toFixed(2);
      setEditKernalCountPercent((prevState) => ({
        ...prevState,
        [key]: percent.toString(),
      }));
    }

    if (event.target.name === "kernal_count_percent") {
      setEditKernalCountPercent((prevState) => ({
        ...prevState,
        [key]: event.target.value.toString(),
      }));

      const count_value = Math.round(
        (event.target.value * tota_kernal_count) / 100
      );
      setEditKernalCount((prevState) => ({
        ...prevState,
        [key]: count_value.toString(),
      }));

      setUpdatedKernalCount((prevState) => ({
        ...prevState,
        [key]: count_value.toString(),
      }));
    }
  };

  const onHandleKernalWeightAndPercenatage = (event, key) => {
    const totat_kernal_weight = scanResult?.kernel_details?.total_kernel_weight;
    if (event.target.name === "kernel_weights") {
      setEditKernalWeight((prevState) => ({
        ...prevState,
        [key]: event.target.value.toString(),
      }));

      setUpdatedKernalWeight((prevState) => ({
        ...prevState,
        [key]: event.target.value.toString(),
      }));

      const percent = (
        (event.target.value * 100) /
        totat_kernal_weight
      ).toFixed(2);
      setEditKernalWeightPercent((prevState) => ({
        ...prevState,
        [key]: percent.toString(),
      }));
    }

    if (event.target.name === "kernel_weights_percent") {
      setEditKernalWeightPercent((prevState) => ({
        ...prevState,
        [key]: event.target.value.toString(),
      }));
      const count_value = (
        (event.target.value * totat_kernal_weight) /
        100
      ).toFixed(4);
      setEditKernalWeight((prevState) => ({
        ...prevState,
        [key]: count_value.toString(),
      }));
      setUpdatedKernalWeight((prevState) => ({
        ...prevState,
        [key]: count_value.toString(),
      }));
    }
  };
  const filteredArray =
    kernals &&
    kernals.length > 0 &&
    kernals.filter(
      (obj) => obj.scanID === scanID && obj.subScanID === subScanID
    );

  const rows = [];
  let headers = [
    {
      label: "",
      align: "left",
      headerColor: "#fff",
      renderer: (value, indexColumn, indexRow, row, rectRow, rectCell) => {
        return ``;
      },
    },
    {
      label: "Particulars",
      align: "left",
      headerColor: "#fff",
    },
  ];

  if (scanResult?.kernel_details?.overall_score_grading) {
    headers.push({
      label: "Grade",
      align: "left",
      headerColor: "#fff",
    });
  } else {
    headers.push({
      label: "Count",
      align: "left",
      headerColor: "#fff",
    });
    // if (isEdit) {
    //   headers.push({
    //     label: "Edit Count",
    //     align: "left",
    //     headerColor: "#fff",
    //   });
    // }
    headers.push({
      label: "Count %",
      align: "left",
      headerColor: "#fff",
    });
    // if (isEdit) {
    //   headers.push({
    //     label: "Edit Count(%)",
    //     align: "left",
    //     headerColor: "#fff",
    //   });
    // }
  }

  if (parseFloat(scanResult?.kernel_details?.total_kernel_weight) > 0) {
    headers.push({
      label: "Weight (gm)",
      align: "left",
      headerColor: "#fff",
    });
    // if (isEdit) {
    //   headers.push({
    //     label: "Edit Weight",
    //     align: "left",
    //     headerColor: "#fff",
    //   });
    // }
    headers.push({
      label: "Weight (%)",
      align: "left",
      headerColor: "red",
    });
    if (isEdit) {
      headers.push({
        label: "Edit Weight(%)",
        align: "left",
        headerColor: "red",
      });
    }
  }

  if (scanResult?.kernel_details?.consider_area) {
    headers.push({
      label: "Area (mm2)",
      align: "left",
      headerColor: "#fff",
    });
  }

  if (scanResult?.kernel_details?.consider_length) {
    headers.push({
      label: "Length (mm)",
      align: "left",
      headerColor: "#fff",
    });
  }
  headers.push({
    label: "Total Mistakes",
    align: "left",
    headerColor: "#fff",
  });
  headers.push({
    label: "% Accuracy",
    align: "left",
    headerColor: "#fff",
  });
  const firstRow = [];
  firstRow.push("");
  firstRow.push(
    scanResult?.kernel_details?.beautify?.translations?.total_kernel_count
  );

  if (scanResult?.kernel_details?.overall_score_grading) {
    firstRow.push(scanResult?.kernel_details?.overall_score_grading);
  } else {
    firstRow.push(scanResult?.kernel_details?.total_kernel_count);
    // isEdit && firstRow.push(<p>{viewUpdatedValues?.updatedKarnelCount ? viewUpdatedValues?.updatedKarnelCount : ''}</p>);
    firstRow.push("100%");
    // isEdit && firstRow.push(<p>{viewUpdatedValues?.updatedKarnelCountPercentage ? (viewUpdatedValues?.updatedKarnelCountPercentage).toFixed(2) : ''}</p>);
  }

  if (parseFloat(scanResult?.kernel_details?.total_kernel_weight) > 0) {
    firstRow.push(scanResult?.kernel_details?.total_kernel_weight);
    // isEdit && firstRow.push(<p>{viewUpdatedValues?.updatedKarnelWeight ? (viewUpdatedValues?.updatedKarnelWeight).toFixed(2) : ''}</p>);
    firstRow.push("100%");
    isEdit && firstRow.push(<p>{viewUpdatedValues?.updatedKarnelWeightPercentage ? (viewUpdatedValues?.updatedKarnelWeightPercentage) : 0}</p>);
  }
  if (scanResult?.kernel_details?.consider_area) {
    firstRow.push("");
  }
  if (scanResult?.kernel_details?.consider_length) {
    firstRow.push("");
  }
  rows.push(firstRow);

  let totalCount = 0;
  let accuracy;
  scanResult?.kernel_details?.classes.forEach((key, index) => {
    let row = [];
    row.push({
      cellType: "box",
      color: scanResult?.processed_image?.encoding_colors[key],
    });
    row.push(
      scanResult?.kernel_details?.beautify?.translations?.kernel_counts[key] +
      " (" +
      scanResult?.processed_image?.encodings[key] +
      ")"
    );

    if (scanResult?.kernel_details?.overall_score_grading) {
      row.push(scanResult?.kernel_details?.grading[key]);
    } else {
      row.push(Number(scanResult?.kernel_details?.kernel_counts[key]));
      // isEdit &&
      //   row.push(
      //     <p>
      //       <input
      //         style={{ width: "100%" }}
      //         value={editKarnelCount[key]}
      //         name="kernal_count"
      //         type="number"
      //         min="0"
      //         onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
      //         onChange={(e) => onHandleKernalCountAndPercenatage(e, key)}
      //       />
      //     </p>
      //   );
      row.push(
        ` ${Number(
          (scanResult?.kernel_details?.kernel_counts[key] /
            scanResult?.kernel_details?.total_kernel_count) *
          100
        ).toFixed(2)}%`
      );
      // isEdit &&
      //   row.push(
      //     <p>
      //       <input
      //         style={{ width: "70%" }}
      //         name="kernal_count_percent"
      //         type="number"
      //         min="0"
      //         onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
      //         value={editKarnelCountPercent[key]}
      //         onChange={(e) => onHandleKernalCountAndPercenatage(e, key)}
      //       />
      //     </p>
      //   );
    }

    if (parseFloat(scanResult?.kernel_details?.total_kernel_weight) > 0) {
      let weightKey = scanResult?.kernel_details?.kernel_weights
        ? scanResult?.kernel_details?.kernel_weights
        : scanResult?.kernel_details?.kernel_weight;
      row.push(Number(weightKey[key]).toFixed(2));
      isEdit &&
        // row.push(
        //   <p>
        //     <input
        //       style={{ width: "70%" }}
        //       value={editKarnelWeight[key]}
        //       name="kernel_weights"
        //       type="number"
        //       min="0"
        //       onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
        //       onChange={(e) => onHandleKernalWeightAndPercenatage(e, key)}
        //     />
        //   </p>
        // );
        row.push(
          ` ${Number(
            (weightKey[key] / scanResult?.kernel_details?.total_kernel_weight) *
            100
          ).toFixed(2)}%`
        );
      isEdit &&
        row.push(
          <p key={key}>
            <input
              style={{ width: "70%" }}
              name="kernel_weights_percent"
              type="number"
              min="0"
              onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              value={editKarnelWeightPercent[key]}
              onChange={(e) => onHandleKernalWeightAndPercenatage(e, key)}
            />
          </p>
        );
    }

    if (scanResult?.kernel_details?.consider_area) {
      row.push(Number(scanResult?.kernel_details?.areas[key]).toFixed(2));
    }

    if (scanResult?.kernel_details?.consider_length) {
      row.push(
        Number(scanResult?.kernel_details?.kernel_lengths[key]).toFixed(2)
      );
    }
    const filteredData =
      filteredArray &&
      filteredArray.filter((item) => item.originalClassName === key);
    const uniqueCombinations = new Map();
    filteredData &&
      filteredData.forEach((obj) => {
        const combination = `${obj.index}-${obj.originalClassName}`;
        if (
          !uniqueCombinations.has(combination) ||
          obj.timeStamp > uniqueCombinations.get(combination).timeStamp
        ) {
          uniqueCombinations.set(combination, obj);
        }
      });
    const filteredKernel = Array.from(uniqueCombinations.values()).filter(
      (obj) => obj.originalClassName !== obj.changedClassName
    );
    const count = filteredKernel ? filteredKernel.length : 0;
    totalCount += count; // Increment the count
    if (filteredData) {
      row.push(Number(count));
    } else {
      row.push(0);
    }
    const numerator =
      Number(scanResult?.kernel_details?.kernel_counts[key]) - count;
    const denominator = Number(scanResult?.kernel_details?.kernel_counts[key]);
    accuracy =
      denominator !== 0
        ? ((numerator / denominator) * 100).toFixed(2) + "%"
        : "0%";
    if (accuracy) {
      row.push(accuracy);
    }
    rows.push(row);
  });

  // const middleRows = [];
  // middleRows.push("");
  // middleRows.push("Total");
  // if (scanResult?.kernel_details?.overall_score_grading) {
  //     middleRows.push('');
  // } else {
  //     middleRows.push('');
  //     middleRows.push("");
  // }
  // if (parseFloat(scanResult?.kernel_details?.total_kernel_weight) > 0) {
  //     middleRows.push('');
  //     middleRows.push("");
  // }
  // if (scanResult?.kernel_details?.consider_area) {
  //     middleRows.push("")
  // }
  // if (scanResult?.kernel_details?.consider_length) {
  //     middleRows.push("")
  // }
  // if (filteredArray) {
  //     middleRows.push(totalCount)
  // }
  // if (accuracy) {
  //     middleRows.push(((Number(scanResult?.kernel_details?.total_kernel_count) - Number(totalCount)) / Number(scanResult?.kernel_details?.total_kernel_count) * 100).toFixed(2) + "%");
  // }
  // rows.push(middleRows);

  const lastRow = [];
  lastRow.push("");
  lastRow.push("Moisture");
  if (scanResult?.scan_metadata) {
    lastRow.push(
      scanResult?.scan_metadata?.moisture
        ? scanResult?.scan_metadata?.moisture + "%"
        : "NA"
    );
  }

  rows.push(lastRow);

  const table = {
    title: "",
    subtitle: "",
    headers: headers,
    rows: rows,
  };

  return (
    <Box>
      <div style={{ marginTop: 20 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead
              style={{ backgroundColor: "aliceblue", textAlign: "left" }}
            >
              <TableRow>
                {table.headers.map((header, index) => (
                  <TableCell key={index} align={header.align}>
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {table.rows.map((row, index) => (
                <TableRow
                  key={index}
                  style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  {row.map((cell, cellIndex) => (
                    <TableCell
                      key={cellIndex}
                      align="left"
                      style={{
                        paddingRight: "7px",
                        backgroundColor: cellIndex === 5 ? 'lightblue' : cellIndex === 6 ? 'lightblue' : 'white',
                      }}
                    >
                      {cell && cell.cellType === "box" ? (
                        <div
                          style={{
                            backgroundColor: cell.color,
                            width: "20px",
                            height: "20px",
                          }}
                        ></div>
                      ) : (
                        <div>{cell}</div>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/* {isEdit && <TempTableComponent
                scanResult={scanResult}
                scanID={scanID}
                subScanID={subScanID}
                kernals={kernals}
                editKarnelCount={editKarnelCount}
                editKarnelCountPercent={editKarnelCountPercent}
                editKarnelWeight={editKarnelWeight}
                editKarnelWeightPercent={editKarnelWeightPercent}
            />} */}
    </Box>
  );
}

//  Table
// export function TempTableComponent({ scanResult, scanID, subScanID, kernals, editKarnelCount, editKarnelWeight, editKarnelCountPercent, editKarnelWeightPercent }) {

//     const filteredArray = kernals && kernals.length > 0 && kernals.filter(obj => obj.scanID === scanID && obj.subScanID === subScanID);

//     const rows = [];
//     let headers = [
//         {
//             label: "",
//             align: "left",
//             headerColor: "#fff",
//             renderer: (value, indexColumn, indexRow, row, rectRow, rectCell) => {
//                 return ``;
//             }
//         },
//         {
//             label: "Particulars",
//             align: "left",
//             headerColor: "#fff"
//         }
//     ];

//     if (scanResult?.kernel_details?.overall_score_grading) {
//         headers.push({
//             label: "Grade",
//             align: "left",
//             headerColor: "#fff"
//         });
//     } else {
//         headers.push({
//             label: "Count",
//             align: "left",
//             headerColor: "#fff"
//         });
//         headers.push({
//             label: "Count %",
//             align: "left",
//             headerColor: "#fff"
//         });
//     }

//     if (parseFloat(scanResult?.kernel_details?.total_kernel_weight) > 0) {
//         headers.push({
//             label: "Weight (gm)",
//             align: "left",
//             headerColor: "#fff"
//         });
//         headers.push({
//             label: "Weight (%)",
//             align: "left",
//             headerColor: "#fff"
//         });
//     }

//     if (scanResult?.kernel_details?.consider_area) {
//         headers.push({
//             label: "Area (mm2)",
//             align: "left",
//             headerColor: "#fff"
//         });
//     }

//     if (scanResult?.kernel_details?.consider_length) {
//         headers.push({
//             label: "Length (mm)",
//             align: "left",
//             headerColor: "#fff"
//         });
//     }
//     headers.push({
//         label: "Total Mistakes",
//         align: "left",
//         headerColor: "#fff"
//     });
//     headers.push({
//         label: "% Accuracy",
//         align: "left",
//         headerColor: "#fff"
//     });
//     const firstRow = [];
//     firstRow.push("");
//     firstRow.push(scanResult?.kernel_details?.beautify?.translations?.total_kernel_count);

//     if (scanResult?.kernel_details?.overall_score_grading) {
//         firstRow.push(scanResult?.kernel_details?.overall_score_grading);
//     } else {
//         firstRow.push(scanResult?.kernel_details?.total_kernel_count);
//         firstRow.push("100%");
//     }

//     if (parseFloat(scanResult?.kernel_details?.total_kernel_weight) > 0) {
//         firstRow.push(scanResult?.kernel_details?.total_kernel_weight);
//         firstRow.push("100%");
//     }
//     if (scanResult?.kernel_details?.consider_area) {
//         firstRow.push("")
//     }
//     if (scanResult?.kernel_details?.consider_length) {
//         firstRow.push("")
//     }
//     rows.push(firstRow);

//     let totalCount = 0;
//     let accuracy;
//     scanResult?.kernel_details?.classes.forEach((key, index) => {
//         let row = [];
//         row.push({
//             cellType: 'box',
//             color: scanResult?.processed_image?.encoding_colors[key],
//         });
//         row.push(scanResult?.kernel_details?.beautify?.translations?.kernel_counts[key] + " (" + scanResult?.processed_image?.encodings[key] + ")");

//         if (scanResult?.kernel_details?.overall_score_grading) {
//             row.push(scanResult?.kernel_details?.grading[key]);
//         } else {
//             row.push(Number(editKarnelCount[key]));
//             row.push(`${editKarnelCountPercent[key]}%`)
//         }

//         if (parseFloat(scanResult?.kernel_details?.total_kernel_weight) > 0) {
//             // let weightKey = scanResult?.kernel_details?.kernel_weights ? scanResult?.kernel_details?.kernel_weights : scanResult?.kernel_details?.kernel_weight;
//             row.push(Number(editKarnelWeight[key]))
//             row.push(`${editKarnelWeightPercent[key]}%`)
//         }

//         if (scanResult?.kernel_details?.consider_area) {
//             row.push(Number(scanResult?.kernel_details?.areas[key]).toFixed(2));
//         }

//         if (scanResult?.kernel_details?.consider_length) {
//             row.push(Number(scanResult?.kernel_details?.kernel_lengths[key]).toFixed(2));
//         }
//         const filteredData = filteredArray && filteredArray.filter((item) => item.originalClassName === key);
//         const uniqueCombinations = new Map();
//         filteredData && filteredData.forEach((obj) => {
//             const combination = `${obj.index}-${obj.originalClassName}`;
//             if (!uniqueCombinations.has(combination) || obj.timeStamp > uniqueCombinations.get(combination).timeStamp) {
//                 uniqueCombinations.set(combination, obj);
//             }
//         });
//         const filteredKernel = Array.from(uniqueCombinations.values()).filter(obj => obj.originalClassName !== obj.changedClassName);
//         const count = filteredKernel ? filteredKernel.length : 0;
//         totalCount += count; // Increment the count
//         if (filteredData) {
//             row.push(Number(count));
//         } else {
//             row.push(0);
//         }
//         const numerator = Number(scanResult?.kernel_details?.kernel_counts[key]) - count;
//         const denominator = Number(scanResult?.kernel_details?.kernel_counts[key]);
//         accuracy = denominator !== 0 ? ((numerator / denominator) * 100).toFixed(2) + "%" : "0%";
//         if (accuracy) {
//             row.push(accuracy);
//         }
//         rows.push(row);
//     });

//     const lastRow = [];
//     lastRow.push("");
//     lastRow.push("Moisture");
//     if (scanResult?.scan_metadata) {
//         lastRow.push(scanResult?.scan_metadata?.moisture ? scanResult?.scan_metadata?.moisture + "%" : 'NA');
//     }

//     rows.push(lastRow);

//     const table = {
//         title: "",
//         subtitle: "",
//         headers: headers,
//         rows: rows
//     };

//     return (
//         <Box>
//             <h3><u>Modified Table</u></h3>
//             <div style={{ marginTop: 20 }}>
//                 <TableContainer component={Paper}>
//                     <Table>
//                         <TableHead style={{ backgroundColor: 'aliceblue', textAlign: "left" }}>
//                             <TableRow>
//                                 {table.headers.map((header, index) => (
//                                     <TableCell key={index} align={header.align} >
//                                         {header.label}
//                                     </TableCell>
//                                 ))}
//                             </TableRow>
//                         </TableHead>
//                         <TableBody>
//                             {table.rows.map((row, index) => (
//                                 <TableRow key={index} style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
//                                     {row.map((cell, cellIndex) => (
//                                         <TableCell key={cellIndex} align="left" style={{
//                                             paddingRight: '7px',
//                                         }}>
//                                             {cell && cell.cellType === 'box' ? (
//                                                 <div style={{ backgroundColor: cell.color, width: '20px', height: '20px' }}></div>
//                                             ) : (
//                                                 <div>
//                                                     {cell}
//                                                 </div>
//                                             )}
//                                         </TableCell>
//                                     ))}
//                                 </TableRow>
//                             ))}
//                         </TableBody>
//                     </Table>
//                 </TableContainer>
//             </div>
//         </Box>
//     );
// }
