import { Box, Button } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../dataContext";
import { getAllDeepAnalysis } from "../utils/api";

const Analysis = () => {
  const nevigate = useNavigate();
  const { deepAnalysis } = useContext(DataContext);
  const keys = deepAnalysis?.data || [];

  useEffect(() => {
    getAllDeepAnalysis();
  }, []);

  const handelRedirect = (item) => {
    let scan_id = item.split(":")[2];
    let sub_scan_id = item.split(":")[3];
    nevigate(`/deep-analysis/${scan_id}/${sub_scan_id}`);
  };
  
  return (
    <div>
      <div style={{ overflowY: "auto", height: "100vh" }}>
        <div>
          <div>
            <div>
              <header
                style={{
                  padding: 15,
                  textAlign: "start",
                  backgroundColor: "#b34d00",
                  color: "white",
                  position: "sticky",
                  top: 0,
                }}
              >
                Deep Analysis
              </header>

              <main>
                <Box>
                  <u>
                    <h4>Deep Analysis Request Arrival</h4>
                  </u>
                </Box>
                {keys !== null && keys.map((item, index) => {
                  return (
                    <div key={index}>
                      <span style={{ marginRight: "5px", Top: "10px" }}>
                        {item}
                      </span>
                      =
                      <Button
                        variant="contained"
                        style={{
                          marginBottom: "15px",
                          marginLeft: "5px",
                          marginTop: "10px",
                        }}
                        onClick={() => handelRedirect(item)}
                      >
                        Update
                      </Button>
                      <br />
                    </div>
                  );
                })}
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analysis;
