import React, { useState, useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
            overflowX: 'auto'
        },
    },
};

function MultipleSelectCheckmarks({
    title,
    options,
    value,
    onChange,
    isLoading,
    displayFormat,
    isRequired,
    clearDropdown
}) {
    const [selectedOptions, setSelectedOptions] = useState(value || []);

    useEffect(() => {
        if (clearDropdown) {
            setSelectedOptions([]);
        }
    }, [clearDropdown]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        if (value.includes('all')) {
            if (selectedOptions.length === options.length) {
                setSelectedOptions([]);
            } else {
                setSelectedOptions(options);
            }
        } else {
            setSelectedOptions(value);
        }
        let selectedValues;
        if (value.includes('all')) {
            selectedValues = options.map(({ id, machine_code }) => ({ id, machine_code }));
        } else {
            selectedValues = value.map(({ id, machine_code }) => ({ id, machine_code }))
        }
        if (onChange) {
            onChange(selectedValues);
        }
    };

    const isAllSelected = selectedOptions.length === options.length;

    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 200, width: 200 }} required={isRequired}>
                <InputLabel id={`multiple-select-${title}-label`}>{title}</InputLabel>
                <Select
                    labelId={`multiple-select-${title}-label`}
                    id={`multiple-select-${title}`}
                    multiple
                    value={selectedOptions}
                    onChange={handleChange}
                    input={<OutlinedInput label={title} />}
                    renderValue={(selected) =>
                        selected.includes('all')
                            ? 'All'
                            : selected.map(displayFormat).join(', ')
                    }
                    MenuProps={MenuProps}
                    disabled={isLoading}
                >
                    <MenuItem value="all">
                        <Checkbox checked={isAllSelected} />
                        <ListItemText primary="All" />
                    </MenuItem>
                    {options.map((option, index) => (
                        <MenuItem key={index} value={option}>
                            <Checkbox checked={selectedOptions.indexOf(option) > -1} />
                            <ListItemText primary={displayFormat(option)} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default MultipleSelectCheckmarks;


