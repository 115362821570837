import { Box, Button, IconButton, Modal, Typography } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid aliceblue',
    boxShadow: 24,
    p: 4,
};

function AppConformationModel({ open, setOpen, title, message, onActionButton, errorMessage }) {
    const handleClose = () => { setOpen(!open) }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    {!errorMessage ?
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton> :
                        ''}
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {title}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {message}
                    </Typography>
                    {errorMessage ?
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', gap: 3, marginTop: 5 }}>
                            <Button variant="contained" onClick={onActionButton}>Close</Button>
                        </Box> :
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', gap: 3, marginTop: 5 }}>
                            <Button variant="contained" onClick={onActionButton}>Confirm</Button>
                            <Button variant="contained" onClick={handleClose}>Close</Button>
                        </Box>}
                </Box>
            </Modal>
        </div>
    )
}

export default AppConformationModel