import React, { useState } from 'react';

const ImageZoom = ({ src, alt, title }) => {
    const [zoomLevel, setZoomLevel] = useState(1);

    const handleZoomIn = () => {
        const maxZoomIn = 2; // You can adjust this value as needed
        setZoomLevel(Math.min(zoomLevel + 0.1, maxZoomIn));
    };

    const handleZoomOut = () => {
        const minZoomOut = 1; // You can adjust this value as needed
        setZoomLevel(Math.max(minZoomOut, zoomLevel - 0.1));
    };

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ marginLeft: '100px' }}>
                <button onClick={handleZoomIn}>Zoom In</button>
                <button onClick={handleZoomOut}>Zoom Out</button>
            </div>
            <div style={{ width: '100%', overflow: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {src ? (
                    <img
                        src={src}
                        alt={alt}
                        title={title}
                        style={{
                            width: '100%',
                            height: 'auto',
                            maxWidth: '400px',
                            maxHeight: '400px',
                            transform: `scale(${zoomLevel})`,
                            transition: 'transform 0.2s ease-in-out',
                        }}
                    />
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                        <span>No image available.</span>
                    </div>
                )}
            </div>
            <p style={{ fontWeight: 'bold' }}>{title}</p>
        </div>
    );
};

export default ImageZoom;
