import React, { useState } from 'react';
import Sidebar from './sidebar';
import { getAndroidCSV, getAndroidCSVForException, getAndroidCSVForAPIFailure } from '../utils/api';
import { Button, CircularProgress, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function AndroidCSV() {
    const [loading, setLoading] = useState(false);
    const [loadingForException, setLoadingForException] = useState(false);
    const [loadingForAPIFailure, setLoadingForAPIFailure] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [processingKey, setProcessingKey] = useState(null);
    const [exceptionKey, setExceptionKey] = useState(null);
    const [apiFailureKey, setApiFailureKey] = useState(null);

    const handleDownloadClick = async () => {
        try {
            setLoading(true);
            const response = await getAndroidCSV(startDate, endDate, processingKey);
            if (response?.status === 404) {
                alert('No data found in Firestore.');
            } else {
                const blob = new Blob([response?.data], { type: 'text/csv' });
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(blob);
                downloadLink.download = `logs${processingKey ? `_processing${processingKey}` : '_processing'}.csv`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        } finally {
            setLoading(false);
        }
    };
    const handleDownloadClickForAPIFailure = async () => {
        try {
            setLoadingForAPIFailure(true);
            const response = await getAndroidCSVForAPIFailure(startDate, endDate, apiFailureKey);
            if (response?.status === 404) {
                alert('No data found in Firestore.');
            } else {
                const blob = new Blob([response?.data], { type: 'text/csv' });
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(blob);
                downloadLink.download = `logs${apiFailureKey ? `_onApiFailure${apiFailureKey}` : '_onApiFailure'}.csv`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        } finally {
            setLoadingForAPIFailure(false);
        }
    };
    const handleDownloadClickForException = async () => {
        try {
            setLoadingForException(true);
            const response = await getAndroidCSVForException(startDate, endDate, exceptionKey);
            if (response?.status === 404) {
                alert('No data found in Firestore.');
            } else {
                const blob = new Blob([response?.data], { type: 'text/csv' });
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(blob);
                downloadLink.download = `logs${exceptionKey ? `_exception${exceptionKey}` : '_exception'}.csv`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        } finally {
            setLoadingForException(false);
        }
    };
    const handleStartDateChange = (date) => {
        if (date) {
            const startOfDay = new Date(date.$d);
            startOfDay.setHours(0, 0, 0, 0);
            setStartDate(startOfDay);
        } else {
            setStartDate(null);
        }
    };

    const handleEndDateChange = (date) => {
        if (date) {
            const endOfDay = new Date(date.$d);
            endOfDay.setHours(23, 59, 59, 999);
            setEndDate(endOfDay);
        } else {
            setEndDate(null);
        }
    };

    return (
        <div>
            <div className="scan-content-container">
                <Sidebar />
                <div className="scan-container" style={{ marginTop: 100 }}>
                    <div style={{ display: "flex", gap: '30px', flexDirection: 'column' }}>
                        <div style={{ display: "flex", gap: '30px' }}>
                            <div className="input-group">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Start Date"
                                        value={startDate && dayjs(startDate)}
                                        onChange={handleStartDateChange}
                                        sx={{ width: '100%' }}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="input-group">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="End Date"
                                        value={endDate && dayjs(endDate)}
                                        onChange={handleEndDateChange}
                                        shouldDisableDate={(date) => date.isBefore(startDate)}
                                        sx={{ width: '100%' }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: 'row', gap: '50px', marginTop: '20px' }}>
                            <div style={{ display: "flex", gap: '20px' }}>
                                <TextField
                                    label="Processing Key"
                                    variant="outlined"
                                    value={processingKey}
                                    onChange={(e) => setProcessingKey(e.target.value)}
                                />
                                <Button
                                    style={{ backgroundColor: 'blue', color: 'white' }}
                                    className='App-bg-color'
                                    onClick={handleDownloadClick}
                                    disabled={loading} // Disable the button while loading
                                >
                                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Processing Log'}
                                </Button>
                            </div>
                            <div style={{ display: "flex", gap: '20px' }}>
                                <TextField
                                    label="Exception Key"
                                    variant="outlined"
                                    value={exceptionKey}
                                    onChange={(e) => setExceptionKey(e.target.value)}
                                />
                                <Button
                                    style={{ backgroundColor: 'blue', color: 'white' }}
                                    className='App-bg-color'
                                    onClick={handleDownloadClickForException}
                                    disabled={loadingForException} // Disable the button while loading
                                >
                                    {loadingForException ? <CircularProgress size={24} color="inherit" /> : 'Exception Log'}
                                </Button>
                            </div>
                            <div style={{ display: "flex", gap: '20px' }}>
                                <TextField
                                    label="API Failure Key"
                                    variant="outlined"
                                    value={apiFailureKey}
                                    onChange={(e) => setApiFailureKey(e.target.value)}
                                />
                                <Button
                                    style={{ backgroundColor: 'blue', color: 'white' }}
                                    className='App-bg-color'
                                    onClick={handleDownloadClickForAPIFailure}
                                    disabled={loadingForAPIFailure} // Disable the button while loading
                                >
                                    {loadingForAPIFailure ? <CircularProgress size={24} color="inherit" /> : 'API Failure Log'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AndroidCSV;