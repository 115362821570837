import { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import { DataContext } from '../dataContext';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Popup from './popup'
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

function SortableTable({ rows, columns, isLoading, isSearch, isSearchForReview, selectedRows, handleDownload, handleCSVDownload, showErrorMessage, selectedScans, downloadCSV }) {
  const [filteredData, setFilteredData] = useState(rows);
  const { searchTerm, setSearchTermData } = useContext(DataContext);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const customLocaleText = {
    noRowsLabel: showErrorMessage ? 'Too Many Scans! Please select Date Filters' : 'There are No Records to display',
  };

  const updatedColumns = columns.map(column => {
    if (column.field === 'image') {
      return {
        ...column,
        renderCell: (params) => (
          <img
            src={params.value}
            alt="Cropped Image"
            style={{ width: 50, height: 50 }}
          />
        ),
      };
    }
    return column;
  });

  useEffect(() => {
    let filteredRows = rows;
    if (isSearch) {
      filteredRows = filteredRows.filter((row) => {
        const fullName = `${row.user.first_name} ${row.user.last_name}`;
        const email = row.user.email;
        const phoneNumber = row.user.phone_number;
        const scanID = row.scan_id;
        const searchTermLower = searchTerm.toLowerCase();
        return (
          (email && email.toLowerCase().includes(searchTermLower)) ||
          (phoneNumber && phoneNumber.toLowerCase().includes(searchTermLower)) ||
          fullName.toLowerCase().includes(searchTermLower) ||
          (scanID && scanID.toLowerCase().includes(searchTermLower))
        );
      });
    }

    if (isSearchForReview) {
      filteredRows = filteredRows && filteredRows.filter((row) => {
        const fullName = row?.userName;
        const email = row?.email;
        const phoneNumber = row?.phone_number;
        const scanID = row?.scanID;
        const searchTermLower = searchTerm.toLowerCase();
        return (
          (email && email !== 'undefined' && email !== null && email.toLowerCase().includes(searchTermLower)) ||
          (phoneNumber && phoneNumber !== 'undefined' && phoneNumber !== null && phoneNumber.toLowerCase().includes(searchTermLower)) ||
          fullName && fullName !== 'undefined' && fullName !== null && fullName.toLowerCase().includes(searchTermLower) ||
          (scanID && scanID !== 'undefined' && scanID !== null && scanID.toLowerCase().includes(searchTermLower))
        );
      });
    }
    setFilteredData(filteredRows);
  }, [searchTerm, rows, isSearch, isSearchForReview]);

  const handleSearchClick = () => {
    setPopupOpen(true);
  };
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
        <>
          {isSearch && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 16 }}>
              <div>
                <TextField
                  label="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTermData(e.target.value)}
                  style={{ marginRight: 16 }}
                />
              </div>
              <div>
                {
                  selectedScans?.length > 0 && (
                    <Button variant="contained" className='App-bg-color' onClick={downloadCSV} style={{ fontSize: '0.8rem', marginRight: 35 }}>
                      Download CSV
                    </Button>
                  )
                }
                <Button variant="contained" className='App-bg-color' onClick={handleSearchClick} style={{ fontSize: '0.8rem', marginRight: 35 }}>
                  Search By Scan ID
                </Button>
              </div>
            </div>
          )}
          {isSearchForReview && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 16 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTermData(e.target.value)}
                  style={{ marginRight: 16 }}
                />
              </div>
              {selectedRows.length > 0 && (
                <div>
                  <Button variant="contained" className='App-bg-color' onClick={handleDownload} style={{ fontSize: '0.8rem', marginRight: 16 }}>
                    Download JSON
                  </Button>
                  <Button variant="contained" className='App-bg-color' onClick={handleCSVDownload} style={{ fontSize: '0.8rem', marginRight: 16 }}>
                    Download Image
                  </Button>
                </div>
              )}
            </div>
          )}
          {isPopupOpen && <Popup title={'Search By ScanID'} open={isPopupOpen} onClose={() => setPopupOpen(false)} />}
        </>
        <div style={{ flex: 1 }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <DataGrid
              rows={filteredData}
              columns={updatedColumns}
              autoHeight
              GridLines="None"
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 15]}
              disableRowSelectionOnClick
              localeText={customLocaleText}
              classes={{
                columnHeader: 'disable-column-header-focus',
                cell: 'disable-cell-focus',
              }}
            />
          )}
        </div>
      </Box>
    </div>
  );
}


export default SortableTable;
