import React, { useEffect, useState, useContext } from 'react'
import Sidebar from './sidebar'
import { getAllUsers, getAllImages } from '../utils/api';
import { Button, CircularProgress } from '@mui/material';
import JSZip from 'jszip';
import AdvanceDropDown from './AdvanceDropDown';
import { DataContext } from '../dataContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

function ScanFiles() {
    const { machines, crops } = useContext(DataContext);
    const [users, setUser] = useState(null)
    const [selectedCrop, setSelectedCrop] = useState(null)
    const [selectedMachine, setSelectedMachine] = useState(null)
    const [selectedUser, setSelectedUser] = useState([])
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [start_date, setStartDate] = useState(new Date('2023-04-01'))
    const [end_date, setEndDate] = useState(new Date())

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [userResponse] = await Promise.all([
                    getAllUsers(),
                ]);

                if (userResponse?.data?.data) {
                    setUser(userResponse.data.data.users);
                } else {
                    handleDataFetchError(userResponse);
                }
            } catch (error) {
                console.error(error);
            }
        };

        const handleDataFetchError = (response) => {
            if (response.response?.status === 500) {
                setError('Internal server error...');
            } else {
                setError('Something went wrong...');
            }
        };

        fetchData();
    }, []);

    const handleCropChange = async (selectedCrop) => {
        setSelectedCrop(selectedCrop)
    }

    const handleStartDateChange = (date) => {
        if (date) {
            const startOfDay = new Date(date.$d);
            startOfDay.setHours(0, 0, 0, 0);
            setStartDate(startOfDay);
        } else {
            setStartDate(null);
        }
    };

    const handleEndDateChange = (date) => {
        if (date) {
            const endOfDay = new Date(date.$d);
            endOfDay.setHours(23, 59, 59, 999);
            setEndDate(endOfDay);
        } else {
            setEndDate(null);
        }
    };

    const fetchUserImages = async () => {
        setError('')
        try {
            setLoading(true)
            const machine_codes = selectedMachine && selectedMachine.map(machine => machine.machine_code)
            let user_scans_list = []
            const promises = selectedUser.map(async (user_id) => {
                const response = await getAllImages(user_id.firebase_user_id, selectedCrop, machine_codes, start_date, end_date);
                const imageArray = [];

                response?.data?.data?.forEach((array) => {
                    const flatArray = [].concat(...array);
                    flatArray.forEach((item) => {
                        if (item.foundImage !== null) {
                            imageArray.push(item);
                        } 
                        if (item.foundBottomImage != null) {
                            imageArray.push(item)
                        }
                    });
                });
                if (imageArray.length) {
                    user_scans_list.push({ scan_file_images: imageArray, firebase_user_id: user_id.firebase_user_id })
                }
            });
            selectedUser.length <= 30 ? await Promise.all(promises) : setError('Please select less than 30 Users. To get better response...');
            selectedUser.length <= 30 && await downloadZip(user_scans_list)
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    }

    const downloadZip = async (user_scans_list) => {
        if (user_scans_list.length === 0) return;
        const zip = new JSZip();
        const crop_detail = crops && crops.find(crop => crop.id === selectedCrop)

        const fetchImage = async (file_url) => {
            try {
                const response = await fetch(file_url);
                if (!response.ok) {
                    throw new Error(`Failed to fetch image: ${response.status} ${response.statusText}`);
                }
                return await response.blob();
            } catch (error) {
                console.error(error);
                return null;
            }
        };

        const fetchPromises = user_scans_list.map(async (userObj) => {
            const firebase_id = userObj.firebase_user_id;
            const user_detail = users?.find(user => user.firebase_user_id === firebase_id);
            const folder = zip.folder(`${user_detail?.first_name}_${user_detail?.last_name}_(${user_detail?.email ? user_detail.email : user_detail?.phone_number})_scan_images`);
        
            const scanImageAndBottomImagePromises = userObj.scan_file_images.map(async (imageObj) => {
                const scan_id = imageObj.scan_id;
                const sub_scan_id = imageObj.sub_scan_id;
                const machine_code = imageObj.machine_code;
                const scan_date = imageObj.scan_date;
                
                const scan_image = imageObj.found_image;
                const bottom_image = imageObj.found_bottom_image;
                
                const scanPromises = [];
                
                if (scan_image) {
                    const { file_name, file_url } = scan_image;
                    const custom_file_name = `${scan_id}_${sub_scan_id}_${machine_code}_${scan_date}_${file_name}`;
                    const imageContent = await fetchImage(file_url);
                    scanPromises.push({ fileName: custom_file_name, content: imageContent });
                }
                
                if (bottom_image) {
                    const { file_name, file_url } = bottom_image;
                    const custom_file_name = `${scan_id}_${sub_scan_id}_${machine_code}_${scan_date}_${file_name}`;
                    const imageContent = await fetchImage(file_url);
                    scanPromises.push({ fileName: custom_file_name, content: imageContent });
                }
        
                return Promise.all(scanPromises);
            });
        
            const fetched_images = await Promise.all(scanImageAndBottomImagePromises.flat());
            
            fetched_images.forEach((images) => {
                images.forEach((image) => {
                    if (image.content) {
                        folder.file(image.fileName, image.content);
                    }
                });
            });
        });
        

        await Promise.all(fetchPromises);

        zip.generateAsync({ type: 'blob' }).then((content) => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(content);
            link.download = `${crop_detail.crop}_scans_files`;
            link.click();
        });
    };

    return (
        <div>
            <div className="scan-content-container">
                <Sidebar />
                <div className="scan-container" style={{ marginTop: 100 }}>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <select
                                value={selectedCrop}
                                required
                                className='cursor'
                                style={{ padding: 10, height: 55, borderRadius: 5, border: '1px solid skyblue' }}
                                onChange={(e) => handleCropChange(e.target.value)}>
                                <option value=''>Choose Crop*</option>
                                {crops && crops.length > 0 && crops.map((item, index) => (
                                    <option key={index} value={item.id}>{item.crop} ({item.variety})</option>
                                ))}
                            </select>
                        </div>
                        <div style={{ marginLeft: 10 }}>
                            <AdvanceDropDown
                                title="Machine"
                                width={200}
                                data_list={machines}
                                unique_key={'machine_code'}
                                show_options_item={(option) => `${option.machine_name} - ${option.machine_code}`}
                                show_name_on_drop_down_box={(option) => `${option.machine_name} - ${option.machine_code}`}
                                filter_items={['machine_name', 'machine_code']}
                                get_selected_data={setSelectedMachine}
                                // reset_drop_down={clearDropdown}
                                required={true}
                            />
                        </div>
                        <div className="input-group" style={{ width: 200, padding: 10, height: 55, borderRadius: 5 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Start Date"
                                    value={start_date && dayjs(start_date)}
                                    onChange={handleStartDateChange}
                                    sx={{ width: '100%' }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="input-group" style={{ width: 200 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="End Date"
                                    value={end_date && dayjs(end_date)}
                                    onChange={handleEndDateChange}
                                    sx={{ width: '100%' }}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                        <div>
                            <AdvanceDropDown
                                title={'User List'}
                                width={380}
                                data_list={users}
                                get_selected_data={setSelectedUser}
                                unique_key={'firebase_user_id'}
                                show_options_item={(option) => `${option.first_name} ${option.last_name} (${option.email ? option.email : option.phone_number})`}
                                show_name_on_drop_down_box={(option) => ` ${option.first_name} ${option.last_name}`}
                                filter_items={['first_name', 'last_name', 'email', 'phone_number']}
                                required
                                badge_count
                            />
                        </div>
                        <div style={{ marginLeft: 10 }}>
                            <Button style={{ backgroundColor: !(selectedCrop && selectedMachine && selectedUser.length) ? 'lightgray' : 'green', color: 'white', height: 55 }} disabled={!(selectedCrop && selectedMachine && selectedUser.length)} onClick={fetchUserImages} >Fetch Images</Button>
                        </div>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        {loading && <CircularProgress />}
                        {error && <p className='App-color-2'>{error}</p>}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ScanFiles