import React, { createContext, useState, useEffect } from "react";
import {
  getAllCrops,
  getAllMachines,
  getAllDeepAnalysis,

} from "./utils/api";
import { useLocation } from 'react-router-dom';
export const DataContext = createContext();
export const DataProvider = ({ children }) => {
  const location = useLocation();
  const [scans, setScans] = useState([]);
  const [crops, setCrops] = useState([]);
  const [machines, setMachines] = useState([]);
  const [selectedCrop, setSelectedCrop] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMachine, setSelectedMachine] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pageSize, setPageSize] = useState(5);
  const [deepAnalysis, setDeepAlysis] = useState();

  const setScanData = (scanData) => {
    setScans(scanData);
  };

  const setSelectedCropData = (cropData) => {
    setSelectedCrop(cropData);
  };
  const setSelectedMachineData = (cropData) => {
    setSelectedMachine(cropData);
  };
  const setSearchTermData = (term) => {
    setSearchTerm(term);
  };

  const fetchData = async () => {
    try {
      const response = await getAllDeepAnalysis();
      if (response?.data) {
        setDeepAlysis(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (location.pathname === '/deep-analysis') {
      fetchData();
      const intervalId = setInterval(() => {
        fetchData();
      }, 5000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, []);

  useEffect(() => {
    const fetchCrops = async () => {
      try {
        const response = await getAllCrops();
        if (response?.data?.data) {
          setCrops(response.data.data.crops);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCrops();
  }, []);
  
  useEffect(() => {
    const fetchMachines = async () => {
      try {
        const response = await getAllMachines();
        if (response?.data?.data) {
          setMachines(response.data.data.machines);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchMachines();
  }, []);

  return (
    <DataContext.Provider
      value={{
        scans,
        setScans,
        setScanData,
        selectedCrop,
        setSelectedCrop,
        setSelectedCropData,
        selectedMachine,
        setSelectedMachine,
        setSelectedMachineData,
        searchTerm,
        setSearchTerm,
        setSearchTermData,
        crops,
        machines,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        pageSize,
        setPageSize,
        deepAnalysis,
      }}
    >
      {isLoading ? <p>Loading Scans...</p> : children}
    </DataContext.Provider>
  );
};
