import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DynamicTable from './dynamicTable'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '90vw',
    maxHeight: '90vh',
    backgroundColor: 'white',
    boxShadow: 24,
    p: 4,
    overflow: 'auto'
};


function TablePopup({ title, open, onClose, dynamicTableForLegend, scan_result, encoding_colors }) {
    const kernelClasses = scan_result && scan_result.classes;
    function getColorForClass(className, encodingColors) {
        if (encodingColors?.encoding_colors.hasOwnProperty(className)) {
            return encodingColors?.encoding_colors[className];
        } else {
            return "#000000";
        }
    }

    const headers = [
        {
            label: "",
            align: "left",
            headerColor: "#fff",
            renderer: (value, indexColumn, indexRow, row, rectRow, rectCell) => {
                return "";
            },
        },
        {
            label: "Class Name",
            align: "left",
            headerColor: "#fff",
        },
        {
            label: "Endpoints",
            align: "left",
            headerColor: "#fff",
        }
    ];

    const rows = [
        ...kernelClasses.map((kernelClass) => {
            const formattedKernelClass = scan_result?.beautify?.translations?.kernel_counts[kernelClass];
            const endpoints = encoding_colors?.encodings[kernelClass];
            const firstCell = {
                cellType: "box",
                color: getColorForClass(kernelClass, encoding_colors),
            };
            return [
                firstCell,
                formattedKernelClass,
                endpoints
            ];
        }),
        [
            {
                cellType: "box",
                color: "black",
            },
            "Can't Determine",
            "CD"
        ],
    ]
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                style={style}
            >
                <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" component="div" id="modal-modal-title" style={{ marginLeft: '20px' }}>
                        {title}
                    </Typography>
                    <div style={{ marginLeft: 'auto', marginRight: '20px' }}>
                        <IconButton aria-label="close" onClick={onClose} style={{ marginLeft: '20px' }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                {dynamicTableForLegend && (
                    <div className="m-20" style={{ width: "500px" }}>
                        <DynamicTable headers={headers} rows={rows} />
                    </div>
                )}
            </Box>
        </Modal>
    );
}

export default TablePopup;
