
import Sidebar from './components/sidebar'
import React, { useEffect, useState, useContext } from 'react'
import SortableTable from './components/sortableTable'
import Button from '@mui/material/Button';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MultipleSelectCheckmarks from './components/multipleSelectDropdown'
import moment from 'moment';
import dayjs from 'dayjs';
import QrPopup from './components/qrPopup';
import { DataContext } from './dataContext';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AdvanceDropDown from './components/AdvanceDropDown';
import AdvancedSubGropuDropDown from './components/AdvancedSubGroupDropdown';
import { getReviewedKernalForCropAndMachine, getImageFromCanvas, getScanResult } from './utils/api';
import Checkbox from '@mui/material/Checkbox';
import JSZip from 'jszip';

function ReviewHistory() {
    const { crops } = useContext(DataContext);
    const { machines } = useContext(DataContext);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [ReviewHistory, setReviewHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [selectedMachine, setSelectedMachine] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [selectedCrop, setSelectedCrop] = useState([]);
    const [varietyList, setVarietyList] = useState([])
    const [selectedVeriety, setSelectedVeriety] = useState([])
    const [uniqueCropList, setUniqueCropList] = useState([])
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowsForExpansion, setSelectedRowForExpansion] = useState([]);
    const [clearDropdown, setClearDropdown] = useState(false);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const columns = [
        {
            field: 'checkbox',
            headerName: '',
            width: 50,
            headerClassName: 'table-background',
            editable: false,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => (
                <Checkbox
                    checked={ReviewHistory.length > 0 && selectedRows.length === ReviewHistory.length}
                    onChange={handleSelectAll}
                    className='App-color'
                    inputProps={{ 'aria-label': 'select all' }}
                />
            ),
            renderCell: (params) => {
                return (
                    <Checkbox
                        checked={selectedRows.some((selectedRow) => selectedRow._id === params.row._id)}
                        onChange={() => handleCheckboxChange(params.row)}
                        className='App-color'
                        inputProps={{ 'aria-label': 'select checkbox' }}
                    />
                );
            },
        },
        {
            field: 'userName',
            headerName: 'User Name',
            width: 150,
            headerClassName: 'table-background',
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email/phone',
            width: 200,
            headerClassName: 'table-background',
            editable: false,
            renderCell: (params) => {
                const user = params.row;
                if (user) {
                    if (user.email && user.phone_number) {
                        return `${user.email}, ${user.phone_number}`;
                    } else if (user.email) {
                        return user.email;
                    } else if (user.phone_number) {
                        return user.phone_number;
                    }
                }
                return '';
            },
            valueGetter: (params) => {
                const user = params.row.user;
                if (user) {
                    if (user.email && user.phone_number) {
                        return `${user.email}, ${user.phone_number}`;
                    } else if (user.email) {
                        return user.email;
                    } else if (user.phone_number) {
                        return user.phone_number;
                    }
                }
                return '';
            },
            filterValueGetter: (params) => {
                const user = params.row.user;
                if (user) {
                    if (user.email && user.phone_number) {
                        return `${user.email}, ${user.phone_number}`;
                    } else if (user.email) {
                        return user.email;
                    } else if (user.phone_number) {
                        return user.phone_number;
                    }
                }
                return '';
            },
        },
        {
            field: 'scanID',
            headerName: 'Scan ID',
            width: 150,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'table-background',
            editable: false,
        },
        {
            field: 'subScanID',
            headerName: 'Sub Scan ID',
            width: 150,
            type: 'number',
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'table-background',
            editable: false,
        },
        {
            field: 'count',
            headerName: 'Count',
            type: 'number',
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'table-background',
            width: 150,
            editable: false,
        },
        {
            field: 'timeStamp',
            headerName: 'Time Stamp',
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'table-background',
            width: 220,
            editable: false,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'table-background',
            width: 120,
            sortable: false,
            editable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Button variant="contained" className='App-bg-color' onClick={() => handleExpandRow(params.row)}>
                    View
                </Button>
            ),
        },
    ];
    const expandedColumns = [
        {
            field: 'checkbox',
            headerName: '',
            width: 50,
            headerClassName: 'table-background',
            editable: false,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => (
                <Checkbox
                    checked={expandedRows.length > 0 && selectedRowsForExpansion.length === expandedRows.length}
                    onChange={handleSelectAllForExpansion}
                    color="primary"
                    inputProps={{ 'aria-label': 'select all' }}
                />
            ),
            renderCell: (params) => {
                return (
                    <Checkbox
                        checked={selectedRowsForExpansion.some((selectedRow) => selectedRow._id === params.row._id)}
                        onChange={() => handleCheckboxChangeForExpansion(params.row)}
                        color="primary"
                        inputProps={{ 'aria-label': 'select checkbox' }}
                    />
                );
            },
        },
        {
            field: 'userName',
            headerName: 'User Name',
            width: 120,
            headerClassName: 'table-background',
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email/phone',
            width: 170,
            headerClassName: 'table-background',
            editable: false,
            renderCell: (params) => {
                const user = params.row;
                if (user) {
                    if (user.email && user.phone_number) {
                        return `${user.email}, ${user.phone_number}`;
                    } else if (user.email) {
                        return user.email;
                    } else if (user.phone_number) {
                        return user.phone_number;
                    }
                }
                return '';
            },
            valueGetter: (params) => {
                const user = params.row.user;
                if (user) {
                    if (user.email && user.phone_number) {
                        return `${user.email}, ${user.phone_number}`;
                    } else if (user.email) {
                        return user.email;
                    } else if (user.phone_number) {
                        return user.phone_number;
                    }
                }
                return '';
            },
            filterValueGetter: (params) => {
                const user = params.row.user;
                if (user) {
                    if (user.email && user.phone_number) {
                        return `${user.email}, ${user.phone_number}`;
                    } else if (user.email) {
                        return user.email;
                    } else if (user.phone_number) {
                        return user.phone_number;
                    }
                }
                return '';
            },
        },
        {
            field: 'scanID',
            headerName: 'Scan ID',
            width: 150,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'table-background',
            editable: false,
        },
        {
            field: 'subScanID',
            headerName: 'Sub Scan ID',
            width: 150,
            type: 'number',
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'table-background',
            editable: false,
        },
        {
            field: 'index',
            headerName: 'Index',
            type: 'number',
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'table-background',
            width: 200,
            editable: false,
        },
        {
            field: 'timeStamp',
            headerName: 'Time Stamp',
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'table-background',
            width: 200,
            editable: false,
        },
        {
            field: 'image',
            headerName: 'Image',
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'table-background',
            width: 200,
            editable: false,
        },
        {
            field: 'originalClassName',
            headerName: 'Original ClassName',
            headerClassName: 'table-background',
            width: 150,
            editable: true,
        },
        {
            field: 'changedClassName',
            headerName: 'Change ClassName',
            headerClassName: 'table-background',
            width: 150,
            editable: true,
        },
    ];

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleSelectAll = (event) => {
        setSelectedRows(event.target.checked ? ReviewHistory : []);
    };
    const handleSelectAllForExpansion = (event) => {
        setSelectedRowForExpansion(event.target.checked ? expandedRows : []);
    };

    const handleCheckboxChange = (row) => {
        const scanId = row.scanID;
        const subscanId = row.subScanID;
        const Rows = ReviewHistory.filter(
            (item) => item.scanID === scanId && item.subScanID === subscanId && item.userID === row.userID
        );
        setSelectedRows((prevSelectedRows) => {
            const rowIds = Rows.map((row) => row._id);
            const isSelected = rowIds.some((id) => prevSelectedRows.some((selectedRow) => selectedRow._id === id));
            if (isSelected) {
                return prevSelectedRows.filter((selectedRow) => !rowIds.includes(selectedRow._id));
            } else {
                return [...prevSelectedRows, ...Rows];
            }
        });
    };

    const handleCheckboxChangeForExpansion = (row) => {
        setSelectedRowForExpansion((prevSelectedRows) => {
            const rowId = row._id;
            if (prevSelectedRows.some((selectedRow) => selectedRow._id === rowId)) {
                return prevSelectedRows.filter((selectedRow) => selectedRow._id !== rowId);
            } else {
                return [...prevSelectedRows, row];
            }
        });
    };
    const handleExpandRow = async (row) => {
        setSnackbarMessage('Loading ...');
        setSnackbarOpen(true);
        const scanId = row.scanID;
        const subscanId = row.subScanID;
        const expandedRows = ReviewHistory.filter(
            (item) => item.scanID === scanId && item.subScanID === subscanId && item.userID === row.userID
        );
        const uniqueRows = [];
        const indexMap = new Map();
        expandedRows?.forEach((item) => {
            const key = `${item.index}_${item.originalClassName}`;

            if (!indexMap.has(key) || item.timeStamp > indexMap.get(key).timeStamp) {
                indexMap.set(key, item);
            }
        });

        indexMap.forEach((value) => {
            uniqueRows.push(value);
        });

        const scanData = await getScanResult(scanId, subscanId);
        const expandedRowsWithIndex = await Promise.all(uniqueRows.map(async (item, index) => {
            const { X, Y, W, H, scanID, subScanID, originalClassName, index: itemIndex, changedClassName } = item;
            const boundingBoxString = JSON.stringify([X, Y, W, H]);
            const formattedDate = moment(item.timeStamp).format('DD MMM, YYYY hh:mm A')
            const { cropUrl, fileName } = await handleCropImage(boundingBoxString, scanID, subScanID, originalClassName, itemIndex, changedClassName, scanData);
            return {
                ...item,
                id: index,
                image: cropUrl,
                fileName: fileName,
                timeStamp: formattedDate,
            };
        }));

        setExpandedRows(expandedRowsWithIndex);
        setPopupOpen(true);
        setSnackbarOpen(false)
    };

    useEffect(() => {
        const uniqueObjects = crops?.length && crops.reduce((unique, currentObj) => {
            const isDuplicate = unique.some(obj => obj.crop === currentObj.crop)
            if (!isDuplicate) { unique.push(currentObj) }
            return unique;
        }, []);
        setUniqueCropList(uniqueObjects);
    }, [crops])

    useEffect(() => {
        if (selectedCrop?.length) {
            const all_verites_crops = crops?.length && crops.filter(item =>
                selectedCrop.some(selectedItem => selectedItem.crop === item.crop)
            );

            // Separate crops with "perfect" in their variety name
            const perfectCrops = all_verites_crops.filter(item =>
                item.variety.toLowerCase().includes('perfect')
            );

            // Filter out crops with "perfect" in their variety name for the main list
            const filteredCrops = all_verites_crops.filter(item =>
                !item.variety.toLowerCase().includes('perfect')
            );

            const output_list = filteredCrops.reduce((accumulator, crop) => {
                const existingCrop = accumulator.find((item) => item.title.toLowerCase() === crop.crop.toLowerCase());
                if (existingCrop) {
                    existingCrop.data.push(crop);
                } else {
                    accumulator.push({
                        title: crop.crop,
                        data: [crop],
                    });
                }
                return accumulator;
            }, []);

            // Add a custom option if there are crops with "perfect" in their variety name
            if (perfectCrops.length) {
                output_list.push({
                    title: 'Custom Variety',
                    data: perfectCrops,
                });
            }

            setVarietyList(output_list);
        } else {
            setVarietyList([]);
        }
    }, [selectedCrop, crops]); // Ensure crops is in the dependency array

    const handleMachineChange = (value) => {
        setSelectedMachine(value);
    };

    const handleStartDateChange = (date) => {
        if (date) {
            const startOfDay = new Date(date.$d);
            startOfDay.setHours(0, 0, 0, 0);
            setStartDate(startOfDay);
        } else {
            setStartDate(null);
        }
    };

    const handleEndDateChange = (date) => {
        if (date) {
            const endOfDay = new Date(date.$d);
            endOfDay.setHours(23, 59, 59, 999);
            setEndDate(endOfDay);
        } else {
            setEndDate(null);
        }
    };
    const handleButtonClick = async () => {
        let endDateParam = null;
        if (endDate) {
            endDateParam = endDate
        } else if (!endDate && startDate) {
            endDateParam = dayjs()
            setEndDate(endDateParam)
        }
        setIsLoading(true);
        const reviweStartDate = moment(startDate).valueOf();
        const reviweEndDate = moment(endDateParam).valueOf();
        const ids = selectedVeriety.map(item => ({ id: item.id }));
        const response = await getReviewedKernalForCropAndMachine(ids, selectedMachine, reviweStartDate, reviweEndDate);
        response?.data?.updatedReviewKernels && setReviewHistory(response.data.updatedReviewKernels)
        setTimeout(() => {
            setIsLoading(false);
        }, 10000);
    };

    const handleRefreshClick = () => {
        setClearDropdown(true);
        setReviewHistory([])
        setSelectedMachine([]);
        setSelectedCrop([]);
        setStartDate(null)
        setEndDate(null);
    };

    const formattedRows = ReviewHistory && ReviewHistory.reduce((acc, row) => {
        const existingIndex = acc.findIndex(item => item.scanID === row.scanID && item.subScanID === row.subScanID && item.userID === row.userID);

        if (existingIndex === -1) {
            const id = row._id;
            const formattedDate = moment(row.timeStamp).format('DD MMM, YYYY hh:mm A');
            acc.push({
                id,
                ...row,
                scanID: row.scanID,
                subScanID: row.subScanID,
                count: 1,
                indexes: [{ index: row.index, originalClassName: row.originalClassName }],
                timeStamp: formattedDate,
            });
        } else {
            if (!acc[existingIndex].indexes.some(indexData => indexData.index === row.index && indexData.originalClassName === row.originalClassName)) {
                acc[existingIndex].count += 1;
                acc[existingIndex].indexes.push({ index: row.index, originalClassName: row.originalClassName });
            }
        }
        return acc;

    }, []);

    const handleDownload = async () => {
        setSnackbarMessage('Downloading ...');
        setSnackbarOpen(true);
        if (selectedRows && selectedRows.length > 0) {
            const uniqueSelectedRowsMap = new Map();
            selectedRows.forEach((row) => {
                const key = `${row.scanID}_${row.subScanID}_${row.index}`;
                if (!uniqueSelectedRowsMap.has(key) || row.timeStamp > uniqueSelectedRowsMap.get(key).timeStamp) {
                    uniqueSelectedRowsMap.set(key, row);
                }
            });
            // Convert map values (unique rows with greatest timestamp) back to an array
            const uniqueSelectedRows = Array.from(uniqueSelectedRowsMap.values());
            const blob = new Blob([JSON.stringify(uniqueSelectedRows)], { type: 'application/json' });
            const link = document.createElement('a');
            const currentDate = moment();
            const formattedDate = currentDate.format('DD_MM_YYYY');
            const formattedTime = currentDate.format('HH_mm');
            link.download = `Reviewed_${formattedDate}_${formattedTime}.json`;
            link.href = window.URL.createObjectURL(blob);
            link.click();
        }
        setSnackbarMessage('Download completed successfully!');
        setTimeout(() => setSnackbarOpen(false), 3000);
    };
    const handleDownloadForExpansion = async () => {
        setSnackbarMessage('Downloading ...');
        setSnackbarOpen(true);
        if (selectedRowsForExpansion && selectedRowsForExpansion.length > 0) {
            const blob = new Blob([JSON.stringify(selectedRowsForExpansion)], { type: 'application/json' });
            const link = document.createElement('a');
            const currentDate = moment();
            const formattedDate = currentDate.format('DD_MM_YYYY');
            const formattedTime = currentDate.format('HH_mm');
            link.download = `Reviewed_${formattedDate}_${formattedTime}.json`;
            link.href = window.URL.createObjectURL(blob);
            link.click();
        }
        setSnackbarMessage('Download completed successfully!');
        setTimeout(() => setSnackbarOpen(false), 3000);
    };
    const downloadZip = async (validCroppedUrls, capturedWebpUrlForBottom) => {
        console.log(validCroppedUrls, capturedWebpUrlForBottom);
        const zip = new JSZip();
        const folder = zip.folder('Images');
        validCroppedUrls && validCroppedUrls.forEach(({ imageData, fileName }) => {
            folder.file(fileName, imageData, { base64: true });
        });
        if (capturedWebpUrlForBottom !== null) {
            capturedWebpUrlForBottom && capturedWebpUrlForBottom.forEach(({ imageData, fileName }) => {
                folder.file(`bottom_image_${fileName}`, imageData, { base64: true });
            });
        }
        zip.generateAsync({ type: 'blob' }).then((content) => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(content);
            link.download = 'Images.zip';
            link.click();
        });
    };

    const handleCropImage = async (boundingBoxString, scanID, subScanID, originalClassName, index, changedClassName, scanData) => {
        if (scanData?.data) {
            let fileName = `${scanID}_${subScanID}_${originalClassName} - ${changedClassName}_${index}.png`
            const capturedImageFileNames = ['captured.webp', 'captured.png', 'captured.jpg'];
            const capturedImageFiles = capturedImageFileNames.map((fileName) =>
                scanData?.data?.scan_result?.scan_files.find((file) => file.file_name === fileName)
            );
            const capturedWebpFileForBottom = scanData?.data?.scan_result?.scan_files.find(
                (file) => file.file_name === 'captured_bottom.webp'
            );

            let capturedWebpUrlForBottom = capturedWebpFileForBottom?.file_url;
            if (!capturedWebpUrlForBottom) {
                const fileWithCvServer = scanData?.data?.scan_result?.scan_files.find(
                    (file) => file.file_name.includes('_bottom_cv_server')
                );
                capturedWebpUrlForBottom = fileWithCvServer?.file_url || null;
            }
            const capturedWebpUrl = capturedImageFiles.find((file) => file !== undefined)?.file_url || scanData?.data?.scan_result?.scan_files
                .find((file) => file.file_name.includes('_cv_server'))?.file_url || null;
            const cropUrl = capturedWebpUrl ? await getImageFromCanvas(capturedWebpUrl, boundingBoxString, true) : null;
            let boxesUrlForBottom = null;
            if (capturedWebpUrlForBottom !== null) {
                boxesUrlForBottom = await getImageFromCanvas(capturedWebpUrlForBottom, boundingBoxString);
            }
            return { cropUrl, boxesUrlForBottom, fileName };
        }
    };
    const handleCSVDownload = async () => {
        setSnackbarMessage('Downloading images...');
        setSnackbarOpen(true);
        const uniqueCombinations = new Map();
        selectedRows && selectedRows.forEach((obj) => {
            const combination = `${obj.index}-${obj.originalClassName}`;
            if (!uniqueCombinations.has(combination) || obj.timeStamp > uniqueCombinations.get(combination).timeStamp) {
                uniqueCombinations.set(combination, obj);
            }
        });
        const filteredKernel = Array.from(uniqueCombinations.values());
        if (filteredKernel.length === 0) {
            setSnackbarMessage('No images to Download.');
            setTimeout(() => setSnackbarOpen(false), 3000);
            return;
        }
        if (filteredKernel.length > 100) {
            setSnackbarMessage('Please select a maximum of 5 Scans.');
            setSnackbarOpen(true);
            return;
        }
        const cropImagePromises = filteredKernel.map(async (item) => {
            const { X, Y, W, H, scanID, subScanID, originalClassName, index, changedClassName } = item;
            const boundingBoxString = JSON.stringify([X, Y, W, H]);
            const scanData = await getScanResult(scanID, subScanID);
            return handleCropImage(boundingBoxString, scanID, subScanID, originalClassName, index, changedClassName, scanData);
        });
        const croppedUrlsWithNames = await Promise.all(cropImagePromises);
        const validCroppedUrls = [];
        const capturedWebpUrlForBottom = [];

        croppedUrlsWithNames?.forEach(({ cropUrl, boxesUrlForBottom, fileName }) => {
            if (cropUrl !== null && cropUrl !== undefined) {
                validCroppedUrls.push({ imageData: cropUrl.split(',')[1], fileName });
            }
            if (boxesUrlForBottom !== null && boxesUrlForBottom !== undefined) {
                capturedWebpUrlForBottom.push({ imageData: boxesUrlForBottom.split(',')[1], fileName });
            }
        });
        downloadZip(validCroppedUrls, capturedWebpUrlForBottom);
        setSnackbarMessage('Download completed successfully!');
        setTimeout(() => setSnackbarOpen(false), 3000);
    }


    return (
        <div className="user-container"><Sidebar isArrow />
            <div className="user-content">
                <div className="table-input m-b-20">
                    <div className="input-group">
                        {crops && crops.length > 0 && (
                            <>
                                <AdvanceDropDown
                                    title="Crop"
                                    width={200}
                                    data_list={uniqueCropList}
                                    unique_key={'id'}
                                    show_options_item={(option) => `${option.crop}`}
                                    show_name_on_drop_down_box={(option) => `${option.crop}`}
                                    filter_items={['crop']}
                                    get_selected_data={setSelectedCrop}
                                    reset_drop_down={clearDropdown}
                                    required={true}
                                />
                                <div style={{ marginLeft: 10 }}>
                                    <AdvancedSubGropuDropDown
                                        title='Variety'
                                        data_list={varietyList}
                                        unique_key={'id'}
                                        show_options_item={(option) => `${option.variety}`}
                                        show_name_on_drop_down_box={(option) => `${option.variety}`}
                                        filter_items={['variety']}
                                        width={250}
                                        get_selected_data={setSelectedVeriety}
                                        reset_drop_down={clearDropdown}
                                        required
                                    />
                                </div>
                            </>)}
                        {machines && machines.length > 0 && (
                            <MultipleSelectCheckmarks
                                title="Machine"
                                options={machines}
                                value={selectedMachine}
                                onChange={handleMachineChange}
                                isLoading={isLoading}
                                clearDropdown={clearDropdown}
                                displayFormat={(option) => `${option.machine_name} - ${option.machine_code}`}
                                isRequired={true}
                            />
                        )}
                    </div>
                    <div className="input-group">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Start Date"
                                value={startDate && dayjs(startDate)}
                                onChange={handleStartDateChange}
                                sx={{ width: '100%' }}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="input-group">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="End Date"
                                value={endDate && dayjs(endDate)}
                                onChange={handleEndDateChange}
                                shouldDisableDate={(date) => date.isBefore(startDate)}
                                sx={{ width: '100%' }}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="input-group">
                        <Button variant="contained" className='App-bg-color' onClick={handleButtonClick} disabled={!(selectedCrop && selectedMachine)} >VIEW</Button>
                    </div>
                    <div className="input-group">
                        <Button variant="contained" className='App-bg-color-2' onClick={handleRefreshClick} style={{ fontSize: '0.8rem', backgroundColor: 'red' }}>
                            Reset
                        </Button>
                    </div>
                </div>
                {isPopupOpen && <QrPopup open={isPopupOpen} onClose={() => setPopupOpen(false)} title='View Changes' tabledata rows={expandedRows} setSelectedRowForExpansion={setSelectedRowForExpansion} selectedRowsForExpansion={selectedRowsForExpansion} handleDownloadForExpansion={handleDownloadForExpansion} expandedColumns={expandedColumns} />}
                <div><SortableTable rows={formattedRows} columns={columns} expandedColumns={expandedColumns} isLoading={isLoading} isSearchForReview selectedRows={selectedRows} handleDownload={handleDownload} handleCSVDownload={handleCSVDownload} expandedRows={expandedRows} /></div>
            </div>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity="success">
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    )
}

export default ReviewHistory