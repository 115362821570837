import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Model from './model'
import { getAllSubScans } from '../utils/api';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function Popup({ title, open, onClose }) {
    const [searchText, setSearchText] = useState('');
    const [scanId, setScanId] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    const handleSearchInputChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        setModalOpen(true);
        setScanId(searchText);
        const response = await getAllSubScans(searchText);
        response?.data?.data && setModalData(response.data.data.sub_scans);
        setIsLoading(false);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div style={{ marginBottom: '18px' }}>
                    <Typography variant="h6" component="div" id="modal-modal-title">
                        {title}
                    </Typography>
                </div>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                    <div style={{ marginRight: '10px' }}>
                        <TextField
                            label="Search"
                            variant="outlined"
                            value={searchText}
                            onChange={handleSearchInputChange}
                        />
                    </div>
                    <Button className='App-bg-color' variant="contained" onClick={handleSubmit}>
                        Search
                    </Button>
                </div>
                {modalOpen && (
                    <Model open={modalOpen} onClose={() => setModalOpen(false)} title={`Select Sub Scan For ${scanId}`} data={modalData} scanID={scanId} isLoading={isLoading} />
                )}
            </Box>

        </Modal>
    );
}

export default Popup;
