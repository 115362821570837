import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { DataProvider } from './dataContext';
import ProtectedRoute from './protected_route';
import Login from './Login';
import User from './User';
import Scan from './Scan';
import ReviewHistory from './reviewHistory';
import DeepAnalysis from './components/DeepAnalysis';
import Analysis from './components/Analysis';
import ScanFiles from './components/ScanFiles';
import AndroidCSV from './components/androidCSV';

const App = () => {
  return (
    <div>
      <DataProvider>
        <Routes>
          <Route path="/" element={<ProtectedRoute><Login /></ProtectedRoute>} />
          <Route path="/user" element={<ProtectedRoute><User /></ProtectedRoute>} />
          <Route path="/reviewHistory" element={<ProtectedRoute><ReviewHistory /></ProtectedRoute>} />
          <Route path="/scan-files" element={<ProtectedRoute><ScanFiles /></ProtectedRoute>} />
          <Route path="/csv" element={<ProtectedRoute><AndroidCSV /></ProtectedRoute>} />
          <Route path="/scan/:id" element={<ProtectedRoute><Scan /></ProtectedRoute>} />
          <Route path="/deep-analysis/:id/:id" element={<ProtectedRoute><DeepAnalysis/></ProtectedRoute>} />
          <Route path="/deep-analysis" element={<ProtectedRoute><Analysis/></ProtectedRoute>} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </DataProvider>
    </div>
  );
};

export default App;
