import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
const ImageViewer = ({ open, onClose, title, imageUrl, imageAlt, isLoading, imageUrlForBottom, imageAltForBottom }) => {
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.2);
  };

  const handleZoomOut = () => {
    if (zoomLevel > 0.2) {
      setZoomLevel(zoomLevel - 0.2);
    }
  };
  
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ maxWidth: '800px', maxHeight: '650px', marginTop: '50px', width: '100%', backgroundColor: '#fff', padding: '16px', borderRadius: '4px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
            <h2 id="modal-title" style={{ margin: 0 }}>{title}</h2>
            <div>
              <IconButton onClick={handleZoomIn} style={{ backgroundColor: '#e0e0e0', marginRight: '8px' }}>
                <AddIcon style={{ color: '#333' }} />
              </IconButton>
              <IconButton onClick={handleZoomOut} style={{ backgroundColor: '#e0e0e0', marginRight: '16px' }}>
                <RemoveIcon style={{ color: '#333' }} />
              </IconButton>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div id="modal-description" style={{ overflow: 'auto' }}>
            {isLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </div>
            ) : (
              <div>
                {imageUrlForBottom ? (
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ width: '50%', overflow: 'auto', marginRight: '10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
                          <h4 style={{ margin: 0 }}>Top View</h4>
                        </div>
                        <img
                          src={imageUrl}
                          alt={imageAlt}
                          style={{
                            width: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                            transform: `scale(${zoomLevel})`,
                            transformOrigin: 'left center',
                            transition: 'transform 0.2s',
                          }}
                        />
                      </div>
                      <div style={{ width: '50%', overflow: 'auto', marginLeft: '10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
                          <h4 style={{ margin: 0 }}>Bottom View</h4>
                        </div>
                        <img
                          src={imageUrlForBottom}
                          alt={imageAltForBottom}
                          style={{
                            width: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                            transform: `scale(${zoomLevel})`,
                            transformOrigin: 'left center',
                            transition: 'transform 0.2s',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div style={{ width: '100%', overflow: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {imageUrl ? (
                      <div style={{ maxWidth: '100%' }}>
                        <img
                          src={imageUrl}
                          alt={imageAlt}
                          style={{
                            maxWidth: '100%',
                            height: '75vh',
                            objectFit: 'contain',
                            transform: `scale(${zoomLevel})`,
                            transformOrigin: 'left top',
                            transition: 'transform 0.2s',
                          }}
                        />
                      </div>
                    ) : (
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                        <span>No captured image present.</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImageViewer;







