import React from 'react';
import { Card, CardContent } from '@mui/material';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
const CustomCard = ({ scanDetails, userDetail, address, scanResult, isLoading, kernelDiamensions }) => {
  return (
    <>
      {isLoading ? (
        <CardContent sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </CardContent>
      ) : (
        <>
          <Card
            sx={{
              backgroundColor: '#E8F5E9',
              borderRadius: '4px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              width: '100%',
              display: 'flex'
            }}
          >
            <>
              <CardContent sx={{ flex: 1 }}>
                <div className="card-container">
                  <div className="key-value-pair">
                    <span className="key">User Name:</span>
                    <span className="value">{`${userDetail?.userInfo?.first_name} ${userDetail?.userInfo?.last_name}`}</span>
                  </div>
                  <div className="key-value-pair">
                    <span className="key">Phone/Email:</span>
                    <span className="value">
                      {userDetail?.userInfo?.email !== null && userDetail?.userInfo?.phone_number !== null ? (
                        <span>
                          {userDetail?.userInfo?.email} / {userDetail?.userInfo?.phone_number}
                        </span>
                      ) : (
                        <span>
                          {userDetail?.userInfo?.email || userDetail?.userInfo?.phone_number}
                        </span>
                      )}
                    </span>
                  </div>

                  <div className="key-value-pair">
                    <span className="key">Grain Type:</span>
                    <span className="value">{scanDetails?.crop_details?.crop}</span>
                  </div>
                  <div className="key-value-pair">
                    <span className="key">Grain Variety:</span>
                    <span className="value">{scanDetails?.crop_details?.variety}</span>
                  </div>
                  <div className="key-value-pair">
                    <span className="key">Machine:</span>
                    <span className="value">{scanDetails?.machine_details?.machine_name} ({scanDetails?.machine_details?.machine_code})</span>
                  </div>
                  {scanResult && scanResult?.moisture !== undefined && (
                    <div className="key-value-pair">
                      <span className="key">Reported Moisture:</span>
                      <span className="value">
                        {scanResult?.moisture}%
                        {scanResult?.moisture_weight && ` (${scanResult?.moisture_weight}gm)`}
                      </span>
                    </div>
                  )}
                  {scanResult && scanResult?.weight !== undefined && (
                    <div className="key-value-pair">
                      <span className="key">Reported Weight:</span>
                      <span className="value">{scanResult.weight} gm</span>
                    </div>
                  )}
                  <div className="key-value-pair">
                    <span className="key">Location:</span>
                    <span className="value">{address?.display_name}</span>
                  </div>
                </div>
              </CardContent>
              <CardContent sx={{ flex: 1 }}>
                <div className="card-container">
                  {scanDetails && scanDetails?.scan_details && scanDetails?.scan_details.length > 0 && (
                    <>
                      <div className="key-value-pair">
                        <span className="key">Result ID:</span>
                        <span className="value">{scanDetails?.scan_details[0]?.scan_id}</span>
                      </div>
                      <div className="key-value-pair">
                        <span className="key">Sub Scan ID:</span>
                        <span className="value">{scanDetails?.scan_details[0]?.sub_scan_id}</span>
                      </div>
                    </>
                  )}
                  <div className="key-value-pair">
                    <span className="key">Scan Date:</span>
                    <span className="value">{moment(scanDetails?.scan_details[0]?.scan_date).format(('DD MMM, YYYY hh:mm A'))}</span>
                  </div>
                  <div className="key-value-pair">
                    <span className="key">Organisation:</span>
                    <span className="value">{userDetail?.companyInfo?.name}</span>
                  </div>
                  <div className="key-value-pair">
                    <span className="key">Application Version:</span>
                    <span className="value">{scanDetails?.scan_result?.scan_result?.scan_metadata?.build_version}</span>
                  </div>
                  <div className="key-value-pair">
                    <span className="key">Phone device Name:</span>
                    <span className="value">{scanDetails?.scan_result?.scan_result?.scan_metadata?.device_info}</span>
                  </div>
                </div>
              </CardContent>
            </>
          </Card>
          {kernelDiamensions && kernelDiamensions.length > 0 && (
            <Card
              sx={{
                backgroundColor: '#E8F5E9',
                borderRadius: '4px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <CardContent sx={{ flex: 1 }}>
                <div className="card-container">
                  {kernelDiamensions.slice(0, Math.ceil(kernelDiamensions.length / 2)).map((item, index) => (
                    <div className="key-value-pair" key={index}>
                      <span className="key">{item.split(':')[0]}</span>
                      <span className="value">{item.split(':')[1]}</span>
                    </div>
                  ))}
                </div>
              </CardContent>
              <CardContent sx={{ flex: 1 }}>
                <div className="card-container">
                  {kernelDiamensions.slice(Math.ceil(kernelDiamensions.length / 2)).map((item, index) => (
                    <div className="key-value-pair" key={index}>
                      <span className="key">{item.split(':')[0]}</span>
                      <span className="value">{item.split(':')[1]}</span>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          )}
        </>
      )}
    </>
  );
}

export default CustomCard;
