import React, { useEffect, useState } from "react";
import { auth } from "./firebase";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const authToken = localStorage.getItem("token");
  const isLoggedIn = authToken !== null;
  const userID = localStorage.getItem("userID");
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user); 
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe(); 
  }, []);

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
//   if (currentUser?.uid === userID) {
//     return <Navigate to="/user" replace />;
//   }
  return children;
};

export default ProtectedRoute;
