import axios from "axios";
import { auth } from "../firebase";
const url = window.config.endpoint;

const getRefreshedTokenId = async () => {
  return new Promise(async (resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      unsubscribe();
      try {
        const refreshedToken = await user.getIdToken(true);
        resolve(refreshedToken);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  });
};
export const getAllDeepAnalysis = async () => {
  try {
    const token = await getRefreshedTokenId();
    const responce = await axios({
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      method: "GET",
      url: `${url}/deep-analysis/read_from_redis`,
    });
    return responce;
  } catch (error) {
    console.log(error);
  }
};

export const AllDeepAnalysisDetails = async (scanId, SubScanId) => {
  try {
    const token = await getRefreshedTokenId();
    const responce = await axios({
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
        "scan_id": `${scanId}`,
        "sub_scan_id": `${SubScanId}`,
      },
      method: "GET",
      url: `${url}/deep-analysis/read_one`,
    });
    return responce;
  } catch (error) {
    console.log(error);
  }
};

export const UpdateDeepAnalysis = async (scanId, SubScanId, detail, callback) => {
  try {
    const token = await getRefreshedTokenId();
    const responce = await axios({
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
        "scan_id": `${scanId}`,
        "sub_scan_id": `${SubScanId}`,
      },
      method: "POST",
      url: `${url}/deep-analysis/update_to_redis`,
      data: detail,
    });
    if (responce) {
      callback(responce)
      return responce;
    }
  } catch (error) {
    console.log(error);
    callback(error)
    return error
  }
};
export const getUserFileImages = async (firebase_user_id, crop_id) => {
  try {
    const token = await getRefreshedTokenId();
    const response = await axios({
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Content-Disposition': 'attachment; filename="scanImages.zip"',
      },
      method: 'POST',
      url: `${url}/scans/get_scan_files`, // get_scan_files
      data: {
        env: "prod",
        firebase_user_id: firebase_user_id, // "gPWK2b5fEtXCn9XBiuzcUk7RAKh1", //firebase_user_id,
        crop_id: crop_id
      },
      responseType: "blob"
    });

    const blob = response.data;
    // Create a temporary link element to trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = "scanImages.zip"; // You can specify the download file name here
    link.style.display = 'none';
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up the temporary link
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error fetching or downloading scan images:", error);
    return error
  }
};

export const getAllUsers = async () => {
  try {
    const token = await getRefreshedTokenId();
    const responce = await axios({
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      method: 'GET',
      url: `${url}/review/all_users`,
    })
    return responce
  } catch (error) {
    console.log(error);
    return error
  }
}

export const getAllCrops = async () => {
  try {
    const token = await getRefreshedTokenId();
    const responce = await axios({
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      method: 'GET',
      url: `${url}/crops/all`,

    })
    return responce
  } catch (error) {
    console.log(error);
    return error
  }
}

export const getAllMachines = async () => {
  try {
    const token = await getRefreshedTokenId();
    const responce = await axios({
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      method: 'GET',
      url: `${url}/machines/all`,
    })
    return responce
  } catch (error) {
    console.log(error);
  }
}
export const getResponseJsonOfFailScan = async (scan_ids) => {
  try {
    console.log(scan_ids, 'scan_detailsscan_detailsscan_details');
    const token = await getRefreshedTokenId();
    const headers = {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
    };
    const response = await axios.post(`${url}/review/get_responseJson_failScan`, {
      scan_ids: scan_ids
    }, {
      headers: headers
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAndroidCSV = async (startDate, endDate, processingKey) => {
  try {
    const token = await getRefreshedTokenId();
    const requestData = {};

    if (startDate && endDate || processingKey) {
      requestData.startDate = startDate;
      requestData.endDate = endDate;
      requestData.processingKey = processingKey;
    }
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      method: 'Post',
      url: `${url}/scans/get_logs_processing_csv`,
      data: requestData,
      responseType: 'blob'
    })
    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
}
export const getAndroidCSVForException = async (startDate, endDate, exceptionKey) => {
  try {
    const token = await getRefreshedTokenId();
    const requestData = {};

    if (startDate && endDate || exceptionKey) {
      requestData.startDate = startDate;
      requestData.endDate = endDate;
      requestData.exceptionKey = exceptionKey;
    }
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      method: 'POST',
      url: `${url}/scans/get_logs_exception_csv`,
      data: requestData,
      responseType: 'blob'
    })
    return response
  } catch (error) {
    console.log(error);
    return error.response;
  }
}
export const getAndroidCSVForAPIFailure = async (startDate, endDate, apiFailureKey) => {
  try {
    const token = await getRefreshedTokenId();
    const requestData = {};

    // Add startDate and endDate to request data if they exist
    if (startDate && endDate || apiFailureKey) {
      requestData.startDate = startDate;
      requestData.endDate = endDate;
      requestData.apiFailureKey = apiFailureKey;
    }

    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      method: 'POST',
      url: `${url}/scans/get_logs_onApiFailure_csv`,
      data: requestData,
      responseType: 'blob'
    });

    return response
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAllScans = async (selectedCrop, selectedMachine, startDate, endDate) => {
  try {
    const token = await getRefreshedTokenId();
    const requestData = {
      crop_id: selectedCrop,
      machine_code: selectedMachine,
    };
    if (startDate && endDate) {
      requestData.start_date = startDate;
      requestData.end_date = endDate;
    }
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      method: 'POST',
      url: `${url}/review/get_scan`,
      data: requestData
    });
    return response;
  } catch (error) {
    console.log(error);
    return error
  }
};

export const getAllSubScans = async (scanID) => {
  try {
    const token = await getRefreshedTokenId();
    const requestData = {
      scan_id: scanID
    };
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      method: 'POST',
      url: `${url}/scans/get_sub_scan_id`,
      data: requestData
    });
    return response;
  } catch (error) {
    console.log(error);
  }
}

export const getAllUserDetails = async (userID) => {
  try {
    const token = await getRefreshedTokenId();
    const requestData = {
      firebase_user_id: userID,
    };
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
      url: `${url}/users/get/other_user`,
      data: requestData,
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getScanResult = async (scanID, subScanID) => {
  try {
    const token = await getRefreshedTokenId();
    const requestData = {
      scan_id: scanID,
      sub_scan_id: subScanID,
    };
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
      url: `${url}/scans/get_scan_result_merged`,
      data: requestData,
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return error.response
    //throw error; 
  }
};
export const getScanFiles = async (scanID, subScanID) => {
  try {
    const token = await getRefreshedTokenId();
    const requestData = {
      scan_id: scanID,
      sub_scan_id: subScanID,
    };
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
      url: `${url}/scans/get_deep_analysis_scan_files`,
      data: requestData,
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return error.response
    //throw error; 
  }
};
export const getScanPdf = async (scanID, subScanID) => {
  try {
    const token = await getRefreshedTokenId();
    const requestData = {
      scan_id: scanID,
      sub_scan_id: subScanID,
      pdf_type: 'LONG'
    };
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
      url: `${url}/scans/generate_scan_pdf_merged`,
      data: requestData,
      responseType: "blob",
    });

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getImageFromCanvas = async (imgUrl, boundingBox) => {
  const fullImageElement = new Image();
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  boundingBox = JSON.parse(boundingBox);
  const boxWidth = boundingBox[2] - boundingBox[0];
  const boxHeight = boundingBox[3] - boundingBox[1];

  return new Promise((resolve, reject) => {
    fullImageElement.onload = () => {
      try {
        canvas.width = boxWidth;
        canvas.height = boxHeight;
        context.drawImage(
          fullImageElement,
          boundingBox[0],
          boundingBox[1],
          boxWidth,
          boxHeight,
          0,
          0,
          boxWidth,
          boxHeight
        );
        const croppedImage = canvas.toDataURL("image/png");
        resolve(croppedImage);
      } catch (error) {
        reject(error);
      }
    };

    fullImageElement.onerror = (error) => {
      reject(error);
    };

    fullImageElement.src = imgUrl;
    fullImageElement.crossOrigin = "anonymous";
  });
};

export const createReviewedKernal = async (reviewed_kernels) => {
  try {
    const token = await getRefreshedTokenId();
    const response = await axios.post(
      `${url}/review/create_review_kernal`,
      reviewed_kernels,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getReviewedKernal = async (scanID, subScanID) => {
  try {
    const token = await getRefreshedTokenId();
    const queryParams = `scanID=${scanID}&subScanID=${subScanID}`;
    const baseUrl = `${url}/review/get_review_kernal?${queryParams}`;

    const response = await axios.get(baseUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getReviewedKernalForCropAndMachine = async (selectedCrop, selectedMachine, start_date, end_date) => {
  try {
    const token = await getRefreshedTokenId();
    const queryParams = new URLSearchParams();
    queryParams.append('crop_id', JSON.stringify(selectedCrop));
    queryParams.append('machine_code', JSON.stringify(selectedMachine));

    if (start_date && end_date) {
      queryParams.append('start_date', start_date);
      queryParams.append('end_date', end_date);
    }
    const response = await axios.get(`${url}/review/get_review_kernal/crops/machines?${queryParams}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCanvasImgData = async (imgurl, boundingBox) => {
  const canvas2d = document.createElement("canvas");
  const context = canvas2d.getContext("2d");
  context.fillStyle = "red";
  context.strokeStyle = "#FF0000";
  context.lineWidth = 20;

  const paintImage = () => {
    return new Promise((resolve) => {
      const imageObj = new Image();
      imageObj.onload = () => {
        const imgWidth = imageObj.width;
        const imgHeight = imageObj.height;

        canvas2d.width = imgWidth;
        canvas2d.height = imgHeight;
        context.strokeStyle = "#FF0000";
        context.lineWidth = 8;
        context.drawImage(imageObj, 0, 0, imgWidth, imgHeight);

        const parsedBox = JSON.parse(boundingBox);
        const boxWidth = parsedBox[2] - parsedBox[0];
        const boxHeight = parsedBox[3] - parsedBox[1];

        context.strokeRect(parsedBox[0], parsedBox[1], boxWidth, boxHeight);

        resolve(true);
      };

      imageObj.src = imgurl;
      imageObj.crossOrigin = "anonymous";
    });
  };

  const isDrawn = await paintImage();

  if (isDrawn) {
    return canvas2d.toDataURL("image/png");
  }
};
export const getCanvasImgDataForArray = async (imgurl, items) => {
  const canvas2d = document.createElement("canvas");
  const context = canvas2d.getContext("2d");
  context.fillStyle = "red";
  context.strokeStyle = "#FF0000";
  context.lineWidth = 20;

  const imObjFunction = () => {
    return new Promise((resolve) => {
      const imageObj = new Image();
      function paintImage() {
        const imgWidth = imageObj.width;
        const imgHeight = imageObj.height;

        canvas2d.width = imgWidth;
        canvas2d.height = imgHeight;
        context.strokeStyle = "#FF0000";
        context.lineWidth = 8;
        context.drawImage(imageObj, 0, 0, imgWidth, imgHeight);

        for (let i = 0; i < items.length; i++) {
          const box = items[i];
          const boxX = box[0];
          const boxY = box[1];
          const boxWidth = box[2] - box[0];
          const boxHeight = box[3] - box[1];

          context.strokeRect(boxX, boxY, boxWidth, boxHeight);
        }

        resolve(true);
      }
      imageObj.src = imgurl;
      imageObj.crossOrigin = "anonymous";
      imageObj.addEventListener("load", paintImage, false);
    });
  };

  const isDrawn = await imObjFunction();

  if (isDrawn) {
    return canvas2d.toDataURL("image/png");
  }
};
export const getAllImages = async (firebase_user_id, crop_id, machine_codes, start_date, end_date) => {
  try {
    const token = await getRefreshedTokenId();
    const requestData = {
      env: "prod",
      firebase_user_id: firebase_user_id,
      crop_id: crop_id,
      machine_codes: machine_codes,
      start_date: start_date,
      end_date: end_date
    };
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      method: 'POST',
      url: `${url}/review/get_scan_files`,
      data: requestData
    });
    return response;
  } catch (error) {
    console.log(error);
  }
}