import Sidebar from './components/sidebar'
import React, { useState, useContext, useEffect } from 'react'
import SortableTable from './components/sortableTable'
import Model from './components/model'
import Button from '@mui/material/Button';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import Popup from './components/popup';
import dayjs from 'dayjs';
import { DataContext } from './dataContext';
import { getAllScans, getAllSubScans, getResponseJsonOfFailScan } from './utils/api';
import { useNavigate } from 'react-router-dom';
import AdvanceDropDown from './components/AdvanceDropDown';
import AdvancedSubGropuDropDown from './components/AdvancedSubGroupDropdown';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

function User() {
  const { crops } = useContext(DataContext);
  const { scans, setScans } = useContext(DataContext);
  const { machines } = useContext(DataContext);
  const [scanID, setScanID] = useState('');
  const [selectedMachine, setSelectedMachine] = useState([]);
  const { startDate, setStartDate } = useContext(DataContext);
  const { endDate, setEndDate } = useContext(DataContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingForSubScans, setIsLoadingForSubScans] = useState(false)
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedCrop, setSelectedCrop] = useState([]);
  const [clearDropdown, setClearDropdown] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [uniqueCropList, setUniqueCropList] = useState([])
  const [varietyList, setVarietyList] = useState([])
  const [selectedVeriety, setSelectedVeriety] = useState([])
  const [selectedRows, setSelectedRows] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();

  const columns = [
    {
      field: 'checkbox',
      headerName: '',
      width: 50,
      headerClassName: 'table-background',
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Checkbox
          checked={scans.length > 0 && selectedRows.length === scans.length}
          onChange={handleSelectAll}
          className='App-color'
          inputProps={{ 'aria-label': 'select all' }}
        />
      ),
      renderCell: (params) => {
        return (
          <Checkbox
            checked={selectedRows.some((selectedRow) => selectedRow.scan_id === params.row.scan_id)}
            onChange={() => handleCheckboxChange(params.row)}
            className='App-color'
            inputProps={{ 'aria-label': 'select checkbox' }}
          />
        );
      },
    },
    {
      field: 'user_name',
      headerName: 'User Name',
      flex: 1,
      width: 150,
      headerClassName: 'table-background',
      editable: false,
      valueGetter: (params) =>
        `${params.row.user.first_name} ${params.row.user.last_name}`,
    },
    {
      field: 'user.email',
      headerName: 'Email/phone',
      flex: 1,
      width: 100,
      headerClassName: 'table-background',
      editable: false,
      renderCell: (params) => {
        const user = params.row.user;
        if (user) {
          if (user.email && user.phone_number) {
            return `${user.email}, ${user.phone_number}`;
          } else if (user.email) {
            return user.email;
          } else if (user.phone_number) {
            return user.phone_number;
          }
        }
        return '';
      },
      valueGetter: (params) => {
        const user = params.row.user;
        if (user) {
          if (user.email && user.phone_number) {
            return `${user.email}, ${user.phone_number}`;
          } else if (user.email) {
            return user.email;
          } else if (user.phone_number) {
            return user.phone_number;
          }
        }
        return '';
      },
      filterValueGetter: (params) => {
        const user = params.row.user;
        if (user) {
          if (user.email && user.phone_number) {
            return `${user.email}, ${user.phone_number}`;
          } else if (user.email) {
            return user.email;
          } else if (user.phone_number) {
            return user.phone_number;
          }
        }
        return '';
      },
    }
    ,
    {
      field: 'crop',
      headerName: 'Crop name',
      flex: 1,
      width: 100,
      headerClassName: 'table-background',
      editable: false,
      valueGetter: (params) =>
        `${params.row.crop} - ${params.row.variety}`,
    },
    {
      field: 'machine_name',
      headerName: 'Machine',
      flex: 1,
      width: 100,
      headerClassName: 'table-background',
      editable: false,
      valueGetter: (params) =>
        `${params.row.machine_name} - ${params.row.machine_code}`,
    },
    {
      field: 'scan_date',
      headerName: 'Date',
      flex: 1,
      width: 161,
      headerClassName: 'table-background',
      editable: false,
      renderCell: (params) => (
        <span>
          {params.value ? params.value : 'No Result'}
        </span>
      ),
    },
    {
      field: 'scan_id',
      headerName: 'Scan ID',
      flex: 1,
      width: 100,
      headerClassName: 'table-background',
      editable: false,
    },
    {
      field: 'totalNoOfScans',
      headerName: 'No of scans',
      flex: 1,
      width: 100,
      headerClassName: 'table-background',
      editable: false,
      type: 'number',
      align: 'center',
      renderCell: (params) => (
        <span >
          {params.value !== null ? params.value : 'No Result'}
        </span>
      ),
    },
    {
      field: 'overall_score',
      headerName: 'Overall Quality Score',
      flex: 1,
      width: 100,
      headerClassName: 'table-background',
      editable: false,
      type: 'number',
      align: 'center',
      renderCell: (params) => (
        <span>
          {params.value ? (params.value.toFixed(2)) : 'No Result'}
        </span>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      headerClassName: 'table-background',
      width: 120,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <CustomActionsColumn
          row={params.row}
          openModal={openModal}
          redirectToScan={() => {
            navigate(`/scan/${params.row.scan_id}-${1}`);
          }}
        />
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      width: 100,
      headerClassName: 'table-background',
      editable: false,
      renderCell: (params) => (
        <span>
          {params.value ? (params.value) : '-'}
        </span>
      ),
    },
  ];

  const handleSelectAll = (event) => {
    setSelectedRows(event.target.checked ? scans : []);
  };

  const handleCheckboxChange = (row) => {
    const scanId = row.scan_id;
    const Rows = scans.filter(
      (item) => item.scan_id === scanId
    );
    setSelectedRows((prevSelectedRows) => {
      const rowIds = Rows.map((row) => row.scan_id);
      const isSelected = rowIds.some((scan_id) => prevSelectedRows.some((selectedRow) => selectedRow.scan_id === scan_id));
      if (isSelected) {
        return prevSelectedRows.filter((selectedRow) => !rowIds.includes(selectedRow.scan_id));
      } else {
        return [...prevSelectedRows, ...Rows];
      }
    });
  };
  useEffect(() => {
    const uniqueObjects = crops?.length && crops.reduce((unique, currentObj) => {
      const isDuplicate = unique.some(obj => obj.crop === currentObj.crop)
      if (!isDuplicate) { unique.push(currentObj) }
      return unique;
    }, []);
    setUniqueCropList(uniqueObjects);
  }, [crops])

  useEffect(() => {
    if (selectedCrop?.length) {
      const all_verites_crops = crops?.length && crops.filter(item =>
        selectedCrop.some(selectedItem => selectedItem.crop === item.crop)
      );
  
      // Separate crops with "perfect" in their variety name
      const perfectCrops = all_verites_crops.filter(item =>
        item.variety.toLowerCase().includes('perfect')
      );
  
      // Filter out crops with "perfect" in their variety name for the main list
      const filteredCrops = all_verites_crops.filter(item =>
        !item.variety.toLowerCase().includes('perfect')
      );
  
      const output_list = filteredCrops.reduce((accumulator, crop) => {
        const existingCrop = accumulator.find((item) => item.title.toLowerCase() === crop.crop.toLowerCase());
        if (existingCrop) {
          existingCrop.data.push(crop);
        } else {
          accumulator.push({
            title: crop.crop,
            data: [crop],
          });
        }
        return accumulator;
      }, []);
  
      // Add a custom option if there are crops with "perfect" in their variety name
      if (perfectCrops.length) {
        output_list.push({
          title: 'Custom Variety',
          data: perfectCrops,
        });
      }
  
      setVarietyList(output_list);
    } else {
      setVarietyList([]);
    }
  }, [selectedCrop, crops]); // Ensure crops is in the dependency array
  

  const CustomActionsColumn = ({ row, openModal, redirectToScan }) => {
    const handleButtonClick = () => {
      if (row.totalNoOfScans === 1) {
        redirectToScan();
      } else {
        openModal(row)
      }
    };
    return (
      <Button className='App-bg-color' variant="contained" onClick={handleButtonClick}>
        View
      </Button>
    );
  };

  const openModal = async (row) => {
    setIsLoadingForSubScans(true);
    setModalOpen(true);
    setScanID(row.scan_id)
    const response = await getAllSubScans(row.scan_id);
    response?.data?.data && setModalData(response.data.data.sub_scans);
    setIsLoadingForSubScans(false);
  };

  const handleStartDateChange = (date) => {
    if (date) {
      const startOfDay = new Date(date.$d);
      startOfDay.setHours(0, 0, 0, 0);
      setStartDate(startOfDay);
    } else {
      setStartDate(null);
    }
  };

  const handleEndDateChange = (date) => {
    if (date) {
      const endOfDay = new Date(date.$d);
      endOfDay.setHours(23, 59, 59, 999);
      setEndDate(endOfDay);
    } else {
      setEndDate(null);
    }
  };

  const handleButtonClickForScans = async () => {
    setIsLoading(true);
    let endDateParam = null;
    if (endDate) {
      endDateParam = endDate
    } else if (!endDate && startDate) {
      endDateParam = dayjs()
      setEndDate(endDateParam)
    }
    const idsArray = selectedVeriety && selectedVeriety.map(item => item.id);
    const machineCodesArray = selectedMachine && selectedMachine.map(item => item.machine_code);
    const response = await getAllScans(idsArray, machineCodesArray, startDate, endDateParam);
    if (response?.data?.data) {
      response?.data?.data && setScans(response.data.data)
      setShowErrorMessage(false);
    } else {
      setShowErrorMessage(true);
    }
    setIsLoading(false);
  };

  const handleRefreshClick = () => {
    setClearDropdown(true);
    setSelectedMachine([]);
    setSelectedCrop([]);
    setSelectedVeriety([])
    setScans([])
    setStartDate(null)
    setEndDate(null);
    setTimeout(() => {
      setClearDropdown(false);
    }, 100);
  };

  const downloadCSV = async () => {
    setSnackbarMessage('Downloading ...');
    setSnackbarOpen(true);
    const scanIds = selectedRows.map(row => row.scan_id);
    const response = await getResponseJsonOfFailScan(scanIds);
    if (response?.data) {
      const data = response.data;
      const csvDataPromises = data.map(async obj => {
        const responseJsonUrl = obj.files.response?.file_url;
        if (!responseJsonUrl) return { scanId: obj.scan_id, ApplicationVersion: obj.build_version, rowImageName: '' };
        try {
          const response = await fetch(responseJsonUrl);
          if (!response.ok) throw new Error(`Failed to fetch JSON file: ${response.status}`);
          const jsonResponse = await response.json();
          const rowImageName = jsonResponse ? jsonResponse.rowImageName || '-' : '-';
          return { scanId: obj.scan_id, ApplicationVersion: obj.build_version, rowImageName: rowImageName };
        } catch (error) {
          console.error(error);
          return { scanId: obj.scan_id, ApplicationVersion: obj.build_version, rowImageName: '' };
        }
      });
      const csvData = await Promise.all(csvDataPromises);
      let csvContent = '';
      const fields = ['scanId', 'ApplicationVersion', 'rowImageName'];
      csvContent += fields.join(',') + '\n';
      csvData.forEach(obj => {
        const row = Object.values(obj).map(value => `"${value}"`).join(',');
        csvContent += row + '\n';
      });
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `RowImageName.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setSelectedRows([])
      setSnackbarMessage('Download completed successfully!');
      setTimeout(() => setSnackbarOpen(false), 3000);
    }
  }
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  const formattedRows = scans && scans.map((row, index) => {
    const id = row.scan_id;
    const formattedDate = moment(row.scan_date).format('DD MMM, YYYY hh:mm A');
    return {
      id,
      ...row,
      scan_date: formattedDate
    }
  });

  return (
    <div className="user-container"><Sidebar />
      <div className="user-content">
        <div className="table-input m-b-20">
          <div className="input-group">
            <AdvanceDropDown
              title="Crop"
              width={200}
              data_list={uniqueCropList}
              unique_key={'id'}
              show_options_item={(option) => `${option.crop}`}
              show_name_on_drop_down_box={(option) => `${option.crop}`}
              filter_items={['crop']}
              get_selected_data={setSelectedCrop}
              reset_drop_down={clearDropdown}
              required={true}
            />
            <div style={{ marginLeft: 10 }}>
              <AdvancedSubGropuDropDown
                title='Variety'
                data_list={varietyList}
                unique_key={'id'}
                show_options_item={(option) => `${option.variety}`}
                show_name_on_drop_down_box={(option) => `${option.variety}`}
                filter_items={['variety']}
                width={250}
                get_selected_data={setSelectedVeriety}
                reset_drop_down={clearDropdown}
                required
              />
            </div>
            <div style={{ marginLeft: 10 }}>
              <AdvanceDropDown
                title="Machine"
                width={200}
                data_list={machines}
                unique_key={'machine_code'}
                show_options_item={(option) => `${option.machine_name} - ${option.machine_code}`}
                show_name_on_drop_down_box={(option) => `${option.machine_name} - ${option.machine_code}`}
                filter_items={['machine_name', 'machine_code']}
                get_selected_data={setSelectedMachine}
                reset_drop_down={clearDropdown}
                required={true}
              />
            </div>
          </div>
          <div className="input-group">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={startDate && dayjs(startDate)}
                onChange={handleStartDateChange}
                sx={{ width: '100%' }}
              />
            </LocalizationProvider>
          </div>
          <div className="input-group">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                value={endDate && dayjs(endDate)}
                onChange={handleEndDateChange}
                shouldDisableDate={(date) => date.isBefore(startDate)}
                sx={{ width: '100%' }}
              />
            </LocalizationProvider>
          </div>
          <div className="input-group">
            <Button variant="contained" className='App-bg-color' onClick={handleButtonClickForScans} disabled={!(selectedCrop.length && selectedMachine.length && selectedVeriety.length)}>Submit</Button>
          </div>
          <div className="input-group">
            <Button variant="contained" className='App-bg-color-2' onClick={handleRefreshClick} style={{ fontSize: '0.8rem' }}>
              Reset
            </Button>
          </div>
          {isPopupOpen && <Popup title={'Search By ScanID'} open={isPopupOpen} onClose={() => setPopupOpen(false)} />}
        </div>
        <div className='table-input'><SortableTable rows={formattedRows} columns={columns} isLoading={isLoading} selectedScans={selectedRows} downloadCSV={downloadCSV} isSearch showErrorMessage={showErrorMessage} /></div>
        {modalOpen && (
          <Model open={modalOpen} onClose={() => setModalOpen(false)} title={`Select Sub Scan For ${scanID}`} data={modalData} scanID={scanID} isLoading={isLoadingForSubScans} />
        )}
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity="success">
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </div>
    </div>
  )
}

export default User