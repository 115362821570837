
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './components/sidebar'
import CustomCard from './components/customCard'
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Drawer from '@mui/material/Drawer';
import moment from 'moment';
import ImageComponent from './components/imageComponent'
import CustomDrawer from './components/customDrawer';
import Model from './components/model'
import ImageViewer from './components/imageViewer';
import { getReviewedKernal, getResponseJsonOfFailScan, getAllSubScans, getAllUserDetails, getScanResult, getScanPdf, getImageFromCanvas, getCanvasImgDataForArray } from './utils/api';
import CircularProgress from '@mui/material/CircularProgress';
import TablePopup from './components/tablePopup';
import QrPopup from './components/qrPopup';
import { Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import TableComponent from './components/table'
import ConfusionMatrix from './components/confusionMatrix';

const MyComponent = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [scanDetails, setScanDetails] = useState({});
  const [userDetail, setUserDetail] = useState({});
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForChanges, setIsLoadingForChanges] = useState(true);
  const [isModelLoading, setIsModelLoading] = useState(false);
  const [cropImageUrls, setCropImageUrls] = useState(null);
  const [cropImageUrlsForBottom, setCropImageUrlsForBottom] = useState(null);
  const [isCanvasLoading, setIsCanvasLoading] = useState(false);
  const [isLoadingForImage, setIsLoadingForImage] = useState(false);
  const [address, setAddress] = useState(null);
  const [kernelDetails, setKernalDetails] = useState(null);
  const [kernelDiamensions, setKernalDiamensions] = useState([]);
  const [scanResult, setScanResult] = useState(null);
  const [scanID, SetScanID] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [subScanID, setSubScanID] = useState(null);
  const [userID, setUserID] = useState(null);
  const [processData, setProcessData] = useState(null);
  const [scanPDFDetails, setScanPDFDetails] = useState(null);
  const [classNameCard, setClassNameCard] = useState('');
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(-1);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isPopupOpenForTable, setPopupOpenForTable] = useState(false);
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [kernals, setKernals] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isTableOpenForMatrix, setIsTableOpenForMatrix] = useState(false);
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [selectedIndicesForBottom, setSelectedIndicesForBottom] = useState([]);
  const [multipleBoxesUrl, setMultipleBoxesUrl] = useState('')
  const [multipleBoxesUrlForBottom, setMultipleBoxesUrlForBottom] = useState('')
  const [bottomImg, setBottomImg] = useState(null)
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [boundingBox, setBoundingBox] = useState([]);
  const [formattedRows, setFormattedRows] = useState([]);
  const [responseJson, setResponseJson] = useState(null)
  const [caturedWebp, setCapturedWebp] = useState(null)
  const columns = [
    {
      field: 'userName',
      headerName: 'User Name',
      width: 150,
      headerClassName: 'table-background',
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email/phone',
      width: 200,
      headerClassName: 'table-background',
      editable: false,
      renderCell: (params) => {
        const user = params.row;
        if (user) {
          if (user.email && user.phone_number) {
            return `${user.email}, ${user.phone_number}`;
          } else if (user.email) {
            return user.email;
          } else if (user.phone_number) {
            return user.phone_number;
          }
        }
        return '';
      },
      valueGetter: (params) => {
        const user = params.row.user;
        if (user) {
          if (user.email && user.phone_number) {
            return `${user.email}, ${user.phone_number}`;
          } else if (user.email) {
            return user.email;
          } else if (user.phone_number) {
            return user.phone_number;
          }
        }
        return '';
      },
      filterValueGetter: (params) => {
        const user = params.row.user;
        if (user) {
          if (user.email && user.phone_number) {
            return `${user.email}, ${user.phone_number}`;
          } else if (user.email) {
            return user.email;
          } else if (user.phone_number) {
            return user.phone_number;
          }
        }
        return '';
      },
    },
    {
      field: 'scanID',
      headerName: 'Scan ID',
      width: 120,
      headerClassName: 'table-background',
      editable: true,
    },
    {
      field: 'subScanID',
      headerName: 'Sub Scan ID',
      width: 100,
      headerClassName: 'table-background',
      editable: true,
    },
    {
      field: 'index',
      headerName: 'Index',
      headerClassName: 'table-background',
      width: 100,
      editable: true,
    },
    {
      field: 'timeStamp',
      headerName: 'Time Stamp',
      headerClassName: 'table-background',
      width: 180,
      editable: true,
    },
    {
      field: 'originalClassName',
      headerName: 'Original ClassName',
      headerClassName: 'table-background',
      width: 150,
      editable: true,
    },
    {
      field: 'changedClassName',
      headerName: 'Change ClassName',
      headerClassName: 'table-background',
      width: 150,
      editable: true,
    }
  ];

  const handleMenuClick = async (event) => {
    setAnchorEl(event.currentTarget);
    const kernalsResponse = await getReviewedKernal(scanID, subScanID)
    setKernals(kernalsResponse?.data?.updatedReviewKernels)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenPopup = async () => {
    try {
      const formattedRows = kernals?.map((row, index) => {
        const formattedDate = moment(row.timeStamp).format('DD MMM, YYYY hh:mm A')
        const id = index;
        return {
          id,
          ...row,
          timeStamp: formattedDate,
        };
      });
      setFormattedRows(formattedRows);
      setPopupOpen(true);
    } catch (error) {
      console.error('Error occurred while fetching kernals:', error);
    } finally {
      setIsLoadingForChanges(false)
    }
  };

  const handleButtonClicked = () => {
    setIsDrawerOpen(true);
  };

  const handleCropImage = async (className) => {
    setSelectAllChecked(false);
    setSelectedIndices([]);
    setSelectedIndicesForBottom([])
    setClassNameCard(className);
    setIsCanvasLoading(true);
    const kernals = await getReviewedKernal(scanID, subScanID);
    setKernals(kernals?.data?.updatedReviewKernels);
    const capturedImageFileNames = ['captured.webp', 'captured.png', 'captured.jpeg'];
    const capturedImageFiles = capturedImageFileNames.map((fileName) =>
      scanDetails?.scan_result?.scan_files.find((file) => file.file_name === fileName)
    );
    const capturedImage = capturedImageFiles.find((file) => file !== undefined);
    const capturedWebpUrl = capturedImage?.file_url || scanDetails?.scan_result?.scan_files
      .find((file) => file.file_name.includes('_cv_server'))?.file_url || null;
    const capturedWebpFileForBottom = scanDetails && scanDetails?.scan_result?.scan_files.find(file => file.file_name === 'captured_bottom.webp');
    let capturedWebpUrlForBottom = capturedWebpFileForBottom?.file_url;
    if (!capturedWebpUrlForBottom) {
      const fileWithCvServer = scanDetails?.scan_result?.scan_files?.find(file => file.file_name.includes('_bottom_cv_server'));
      capturedWebpUrlForBottom = fileWithCvServer?.file_url || null;
    }
    setBottomImg(capturedWebpUrlForBottom)
    const boundingBox = getBoundingBoxByClassName(className);
    const boundingBoxForBottom = getBoundingBoxForBottomByClassName(className);
    const boundingBoxStrings = boundingBox && boundingBox.map((box) => JSON.stringify(box));
    const boundingBoxStringsForBottom = boundingBoxForBottom && boundingBoxForBottom.map((box) => JSON.stringify(box));
    const croppedImages = [];
    const croppedImagesForBottom = [];
    for (const boundingBoxString of boundingBoxStrings) {
      const cropUrl = capturedWebpUrl ? await getImageFromCanvas(capturedWebpUrl, boundingBoxString, true) : null;
      if (cropUrl !== null) {
        croppedImages.push(cropUrl);
      }
    }
    if (boundingBoxStringsForBottom && boundingBoxStringsForBottom.length > 0) {
      for (const boundingBoxStringForBottom of boundingBoxStringsForBottom) {
        const cropUrlForBottom = capturedWebpUrlForBottom ? await getImageFromCanvas(capturedWebpUrlForBottom, boundingBoxStringForBottom) : null;
        if (cropUrlForBottom !== null) {
          croppedImagesForBottom.push(cropUrlForBottom);
        }
      }
    }
    setCropImageUrls(croppedImages);
    setCropImageUrlsForBottom(croppedImagesForBottom)
    setIsCanvasLoading(false);
  };

  const showBoundingBoxes = async () => {
    setOpen(true);
    setIsLoadingForImage(true);
    const capturedImageFileNames = ['captured.webp', 'captured.png', 'captured.jpeg'];
    const capturedImageFile = capturedImageFileNames.find((fileName) =>
      scanDetails?.scan_result?.scan_files.some((file) => file.file_name === fileName)
    );
    // const capturedImageUrl = capturedImageFile
    //   ? scanDetails?.scan_result?.scan_files.find((file) => file.file_name === capturedImageFile)?.file_url
    //   : null;
    const capturedImage = scanDetails?.scan_result?.scan_files?.find((file) => file.file_name === capturedImageFile)?.file_url
    const capturedImageUrl = capturedImage || scanDetails?.scan_result?.scan_files
      .find((file) => file.file_name.includes('_cv_server'))?.file_url || null;
    const capturedWebpFileForBottom = scanDetails?.scan_result?.scan_files.find(
      (file) => file.file_name === 'captured_bottom.webp'
    );
    let capturedWebpUrlForBottom = capturedWebpFileForBottom?.file_url || null;
    if (!capturedWebpUrlForBottom) {
      const fileWithCvServer = scanDetails?.scan_result?.scan_files.find(
        (file) => file.file_name.includes('_bottom_cv_server')
      );

      capturedWebpUrlForBottom = fileWithCvServer?.file_url || null;
    }

    const selectedBoundingBox = selectedIndices;
    const selectedBoundingBoxForBottom = selectedIndicesForBottom
    let boxesUrl;
    let boxesUrlForBottom;

    if (selectedBoundingBox) {
      if (capturedImageUrl !== null) {
        boxesUrl = await getCanvasImgDataForArray(capturedImageUrl, selectedBoundingBox);
      }
      if (capturedWebpUrlForBottom !== null) {
        boxesUrlForBottom = await getCanvasImgDataForArray(capturedWebpUrlForBottom, selectedBoundingBoxForBottom);
      }
    }

    setMultipleBoxesUrlForBottom(boxesUrlForBottom);
    setMultipleBoxesUrl(boxesUrl);
    setIsLoadingForImage(false);
  };

  const getBoundingBoxByClassName = (className) => {
    if (processData?.bboxes && processData?.bboxes.hasOwnProperty(className)) {
      return processData?.bboxes[className];
    }
    return null;
  };
  const getBoundingBoxForBottomByClassName = (className) => {
    if (processData?.bboxes_bottom && processData?.bboxes_bottom.hasOwnProperty(className)) {
      return processData?.bboxes_bottom[className];
    }
    return null;
  };

  useEffect(() => {
    const fetchScans = async () => {
      try {
        setIsLoading(true);
        const path = window.location.pathname;
        const regex = /\/scan\/([^/]+)-(\d+)/;
        const match = path.match(regex);
        if (match && match.length === 3) {
          const scanID = match[1];
          const subScanID = parseInt(match[2], 10);
          SetScanID(scanID);
          setSubScanID(subScanID);
          const scanData = await getScanResult(scanID, subScanID)
          if (scanData.status === 500) {
            const response = await getResponseJsonOfFailScan([scanID]);
            if (response?.data) {
              setResponseJson(response?.data[0]?.files.response)
              setCapturedWebp(response?.data[0]?.files.captured)
            }
            setErrorMessage(scanData)
          }
          const scanPdf = await getScanPdf(scanID, subScanID)
          if (scanPdf) {
            setScanPDFDetails(scanPdf)
          }
          if (scanData?.data) {
            const userID = scanData?.data?.scan_details[0]?.firebase_user_id;
            setUserID(userID)
            const userDetailsResponse = await getAllUserDetails(userID);
            setUserDetail(userDetailsResponse?.data)
            setScanDetails(scanData?.data)
            setKernalDetails(scanData?.data?.scan_result?.scan_result?.kernel_details)
            setProcessData(scanData?.data?.scan_result?.scan_result?.processed_image)
            setScanResult(scanData?.data?.scan_result?.scan_result?.scan_metadata)
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${scanData?.data?.scan_result?.scan_result?.scan_metadata?.latitude}&lon=${scanData?.data?.scan_result?.scan_result?.scan_metadata?.longitude}&zoom=18&addressdetails=0`);
            const data = await response.json();
            setAddress(data);
            const titleCase = (s) => s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase());
            let user_metadata_keys_array_filtered = []
            let scan_metadata_keys_array_filtered = []
            if (scanData?.data?.scan_result?.scan_result?.scan_metadata?.user_metadata) {
              const user_metadata_keys_array = Object.keys(scanData?.data?.scan_result?.scan_result?.scan_metadata?.user_metadata)
              user_metadata_keys_array_filtered = user_metadata_keys_array.filter((key) => scanData?.data?.scan_result?.scan_result?.scan_metadata?.user_metadata[key] !== "")
            }
            const scan_metadata = scanData?.data?.scan_result?.scan_result?.scan_metadata || {}
            if (scan_metadata) {
              const scan_metadata_keys_array = Object.keys(scan_metadata);
              const keysToDisplay = ['chalky_sensitivity', 'discolor_sensitivity'];
              scan_metadata_keys_array_filtered = scan_metadata_keys_array.filter((key) => keysToDisplay.includes(key));
            }
            const dist_avg_indexes = scanData?.data?.scan_result?.scan_result?.dist_avg_indexes;
            const dist_keys_array = dist_avg_indexes?.distribution_avg_values_keys;
            const final_array = [...scan_metadata_keys_array_filtered, ...user_metadata_keys_array_filtered, ...dist_keys_array]
            const kernelAvgHeight = parseFloat(dist_avg_indexes?.distribution_avg_values?.kernel_avg_height);
            const kernelAvgWidth = parseFloat(dist_avg_indexes?.distribution_avg_values?.kernel_avg_width);
            let lbRatio = null;
            if (!isNaN(kernelAvgHeight) && !isNaN(kernelAvgWidth) && scanData?.data?.crop_details?.crop === "Rice") {
              lbRatio = (kernelAvgHeight / kernelAvgWidth).toFixed(2);
              const lbRatioKey = 'L/B Ratio';
              const lbIndex = final_array.indexOf('kernel_avg_width') + 1;
              final_array.splice(lbIndex, 0, lbRatioKey);
            }
            const resultArray = [];
            final_array.forEach((key) => {
              if (key === 'L/B Ratio' && lbRatio !== null) {
                resultArray.push(`L/B Ratio: ${lbRatio}`)
              }
              const user_metadata = scanData?.data?.scan_result?.scan_result?.scan_metadata?.user_metadata || {}
              const scan_metadata = scanData?.data?.scan_result?.scan_result?.scan_metadata || {}
              if (key in dist_avg_indexes?.beautify?.translations?.distribution_avg_values) {
                const translation = dist_avg_indexes?.beautify?.translations?.distribution_avg_values[key];
                const value = dist_avg_indexes?.distribution_avg_values[key] || "NA";
                const dimension = dist_avg_indexes?.beautify?.dimensions?.distribution_avg_values[key] || "";
                resultArray.push(`${translation}: ${value} ${dimension}`)
              } else if (key in user_metadata) {
                const translation = titleCase(key);
                const value = scanData?.data?.scan_result?.scan_result?.scan_metadata?.user_metadata[key];
                resultArray.push(`${translation}: ${value}`)
              } else if (key in scan_metadata) {
                const translation = titleCase(key);
                const value = scanData?.data?.scan_result?.scan_result?.scan_metadata[key]
                resultArray.push(`${translation}: ${value}`)
              }
              setKernalDiamensions(resultArray)
            });
          }

        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchScans();
  }, [window.location.pathname]);

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/user');
  };
  const downloadWebp = async () => {
    const response = await fetch(caturedWebp?.file_url);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${scanID}_${subScanID}_captured.webp`;
    link.click();
  };

  if (errorMessage) {
    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <IconButton sx={{ color: '#2e7d32' }} onClick={handleClick}>
            <ArrowBackIcon />
          </IconButton>
          {errorMessage && (
            <p style={{ marginLeft: '8px', textAlign: 'center' }}>
              There is no Scan to show.
            </p>
          )}
        </div>
        {responseJson ?
          <div style={{ marginTop: "20px" }}>
            <iframe src={responseJson.file_url} style={{ width: '100%', height: '200px' }}></iframe>
          </div> :
          <p style={{ marginLeft: '8px', textAlign: 'center' }}>
            There is no Response.json to show.
          </p>
        }
        {caturedWebp ?
          (<div style={{ marginTop: "20px" }}>
            <Button variant="contained" color="success" onClick={downloadWebp}>
              Download Captured.Webp
            </Button>
          </div>) :
          <p style={{ marginLeft: '8px', textAlign: 'center' }}>
            There is no Captured.Webp to show.
          </p>
        }
      </>
    );
  }
  const handleCheckboxClick = async (className, index) => {
    const boundingBox = getBoundingBoxByClassName(className);
    const boundingBoxForBottom = getBoundingBoxForBottomByClassName(className);
    setBoundingBox(boundingBox)
    if (index != null) {
      if (selectedIndices.length === boundingBox.length) {
        setSelectedIndices([]);
        return
      }
      console.log(selectedIndicesForBottom, boundingBoxForBottom);
      if (selectedIndicesForBottom?.length !== null && boundingBoxForBottom?.length !== null && selectedIndicesForBottom?.length === boundingBoxForBottom?.length) {
        setSelectedIndicesForBottom([]);
        return;
      }
      const selectedIndex = boundingBox[index];
      if (selectedIndices.includes(selectedIndex)) {
        setSelectedIndices(selectedIndices.filter(i => i !== selectedIndex));
      } else {
        setSelectedIndices([...selectedIndices, selectedIndex]);
      }
      const selectedIndexForBottom = boundingBoxForBottom && boundingBoxForBottom[index];
      if (selectedIndicesForBottom.includes(selectedIndexForBottom)) {
        setSelectedIndicesForBottom(selectedIndicesForBottom.filter(i => i !== selectedIndexForBottom));
      } else {
        setSelectedIndicesForBottom([...selectedIndicesForBottom, selectedIndexForBottom]);
      }
    } else {
      if (selectedIndices.length === boundingBox.length) {
        setSelectedIndices([]);
        return
      } else {
        setSelectedIndices(boundingBox);
      }
      if (selectedIndicesForBottom?.length !== null && boundingBoxForBottom?.length !== null && selectedIndicesForBottom?.length === boundingBoxForBottom?.length) {
        setSelectedIndicesForBottom([]);
        return
      } else {
        setSelectedIndicesForBottom(boundingBoxForBottom);
      }
    }

  };

  const openModal = async () => {
    setIsModelLoading(true)
    setModalOpen(true);
    const response = await getAllSubScans(scanID);
    response?.data?.data && setModalData(response.data.data.sub_scans);
    setIsModelLoading(false);
  };

  return (
    <div className="scan-content-container">
      <Sidebar scanID={scanID} subScanID={subScanID} isArrow />
      <div className="scan-container">
        <div className="back-arrow" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
          </div>
          {(kernelDetails?.overall_score || kernelDetails?.overall_score_grading) && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
              {kernelDetails?.overall_score && (
                <Button variant="outlined" color="success">
                  Overall Score: {kernelDetails?.overall_score}
                </Button>
              )}
              {kernelDetails?.overall_score_grading && (
                <Button variant="outlined" color="success">
                  Grading: {kernelDetails?.overall_score_grading}
                </Button>
              )}
            </div>
          )}
          <div style={{ display: 'flex' }}>
            <Button color="success" variant="outlined" onClick={() => openModal()} style={{ marginRight: '10px' }}> Get Sub Scans</Button>
            <Button color="success" variant="outlined" onClick={handleButtonClicked}> Get Files</Button>
          </div>
          {modalOpen && (
            <Model open={modalOpen} onClose={() => setModalOpen(false)} title={`Select Sub Scan For ${scanID}`} data={modalData} scanID={scanID} subScanID={subScanID} isLoading={isModelLoading} />
          )}
        </div>
        <div className="scan-content">
          <CustomCard errorMessage={errorMessage} scanDetails={scanDetails} userDetail={userDetail} address={address} scanResult={scanResult} kernelDiamensions={kernelDiamensions} isLoading={isLoading} />
        </div>
        {
          (kernelDetails?.total_kernel_count || kernelDetails?.total_kernel_weight) && (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
              <div>
                {kernelDetails?.total_kernel_count && (
                  <Button variant="outlined" color="success" style={{ marginRight: '20px' }}>
                    Kernel Count: {kernelDetails?.total_kernel_count}
                  </Button>
                )}
                {kernelDetails?.total_kernel_weight && (
                  <Button variant="outlined" color="success">
                    Predicted kernel Weight: {kernelDetails?.total_kernel_weight} gm
                  </Button>
                )}
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <Button variant="outlined" color="success" onClick={() => showBoundingBoxes()}>
                  View All Kernels
                </Button>
                <label>
                  <input
                    type="checkbox"
                    checked={selectedIndices?.length !== 0 && selectedIndices?.length === parseInt(kernelDetails?.kernel_counts[classNameCard])}
                    onChange={() => handleCheckboxClick(classNameCard)}
                  />
                  Select All
                </label>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleMenuClick}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}><Button variant="outlined" color="success" onClick={handleOpenPopup}>
                    View Changes
                  </Button></MenuItem>
                  <MenuItem onClick={handleClose}><Button variant="outlined" color="success" onClick={() => setIsTableOpen(true)}>
                    View Table
                  </Button></MenuItem>
                  <MenuItem onClick={handleClose}><Button variant="outlined" color="success" onClick={() => setIsTableOpenForMatrix(true)}>
                    View Confusion Matrix
                  </Button></MenuItem>
                  <MenuItem onClick={handleClose}><Button variant="outlined" color="success" onClick={() => setPopupOpenForTable(true)}>
                    View Legend
                  </Button></MenuItem>
                </Menu>
              </div>
              {open && (
                <>
                  <ImageViewer
                    open={open}
                    isLoading={isLoadingForImage}
                    onClose={handleCloseModal}
                    title="Image Viewer"
                    imageUrl={multipleBoxesUrl}
                    imageUrlForBottom={multipleBoxesUrlForBottom}
                    imageAlt={'captured Image'}
                    imageAltForBottom={'Bottom captured Image'}
                  />
                </>
              )}
              {isTableOpenForMatrix && <ConfusionMatrix open={isTableOpenForMatrix} onClose={() => setIsTableOpenForMatrix(false)} kernals={kernals} scanResult={scanDetails?.scan_result?.scan_result} scanDetails={scanDetails} address={address} scanID={scanID} subScanID={subScanID} userDetail={userDetail} title='View Confusion Matrix' />}
              {isTableOpen && <TableComponent open={isTableOpen} onClose={() => setIsTableOpen(false)} scanResult={scanDetails?.scan_result?.scan_result} scanDetails={scanDetails} address={address} kernals={kernals} scanID={scanID} subScanID={subScanID} userDetail={userDetail} title='View Table' />}
              {isPopupOpenForTable && <TablePopup open={isPopupOpenForTable} onClose={() => setPopupOpenForTable(false)} encoding_colors={processData} scan_result={kernelDetails} title='View Legend' dynamicTableForLegend />}
              {isPopupOpen && <QrPopup open={isPopupOpen} onClose={() => setPopupOpen(false)} title='View Changes' rows={formattedRows} expandedColumns={columns} scanID={scanID} subScanID={subScanID} tabledata isLoadingForChanges={isLoadingForChanges} />}
            </div>
          )
        }
        <div className="tab-content">
          {kernelDetails?.classes && kernelDetails.classes.map((className, index) => (
            <React.Fragment key={index}>
              <Button
                style={{
                  color: 'black',
                  border: `3px solid ${processData?.encoding_colors[className]}`,
                  ...(classNameCard === className && {
                    background: `${processData?.encoding_colors[className]}`,
                    color: 'white'
                  })
                }}
                onClick={() => {
                  setSelectedButtonIndex(index);
                  handleCropImage(className);
                }}
              >
                {`${className.replace(/_/g, ' ')} - ${processData?.encodings[className]} - ${kernelDetails?.kernel_counts[className] !== null ? kernelDetails?.kernel_counts[className] : 0}`}
              </Button>
            </React.Fragment>
          ))}
        </div>
        <div>
          {isCanvasLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <div>
                {cropImageUrls && cropImageUrls.length > 0 ? (
                  <div className='tab-content'>
                    {cropImageUrls && cropImageUrls.map((imageUrl, index) => (
                      <React.Fragment key={`${imageUrl}-${index}`}>
                        <ImageComponent
                          key={index}
                          index={index}
                          imageUrl={imageUrl}
                          imageUrlForBottom={cropImageUrlsForBottom ? cropImageUrlsForBottom[index] : ''}
                          kernelDetails={kernelDetails}
                          classNameCard={classNameCard}
                          processData={processData}
                          selectedButtonIndex={selectedButtonIndex}
                          handleCropImage={handleCropImage}
                          scanId={scanID}
                          text={bottomImg ? 'Top' : ''}
                          selectedIndices={selectedIndices}
                          setSelectedIndices={setSelectedIndices}
                          subScanId={subScanID}
                          userID={userID}
                          scanDetails={scanDetails}
                          kernals={kernals}
                          bottomImg={bottomImg}
                          handleCheckboxClick={handleCheckboxClick}
                          boundingBox={boundingBox}
                          setKernals={setKernals}
                          selectAllChecked={selectAllChecked}
                        />
                      </React.Fragment>
                    ))}
                  </div>
                ) : (
                  cropImageUrls && cropImageUrls.length === 0 ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <p>There is no image to show.</p>
                    </div>
                  ) : (
                    kernelDetails?.classes &&
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <p>Please Select Class</p>
                    </div>
                  )
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          sx: {
            width: '25%',
            backgroundColor: '#f5f5f5',
          },
        }}
      >
        <CustomDrawer onClose={handleDrawerClose} scanID={scanID} subScanID={subScanID} sacnFiles={scanDetails?.scan_result?.scan_files} scanPDFDetails={scanPDFDetails} isLoading={isLoading} />
      </Drawer>
    </div>

  );
};

export default MyComponent;
