import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, Button, Box, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import moment from 'moment';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '100%',
    maxHeight: '100vh',
    backgroundColor: 'white',
    overflow: 'auto',
    padding: '20px',
    margin: '20px',
    p: 4,
    WebkitOverflowScrolling: 'touch',
    scrollbarWidth: 'thin',
    scrollbarColor: 'transparent transparent',
};

function TableComponent({ open, onClose, title, scanResult, scanID, subScanID, userDetail, address, scanDetails, kernals }) {
    const filteredArray = kernals && kernals.length > 0 && kernals.filter(obj => obj.scanID === scanID && obj.subScanID === subScanID);
    const rows = [];
    let headers = [
        {
            label: "",
            align: "left",
            headerColor: "#fff",
            renderer: (value, indexColumn, indexRow, row, rectRow, rectCell) => {
                return ``;
            }
        },
        {
            label: "Particulars",
            align: "left",
            headerColor: "#fff"
        }
    ];

    if (scanResult?.kernel_details?.overall_score_grading) {
        headers.push({
            label: "Grade",
            align: "left",
            headerColor: "#fff"
        });
    } else {

    }
    headers.push({
        label: "Count",
        align: "left",
        headerColor: "#fff"
    });
    headers.push({
        label: "Count %",
        align: "left",
        headerColor: "#fff"
    });
    if (parseFloat(scanResult?.kernel_details?.total_kernel_weight) > 0) {
        headers.push({
            label: "Weight (gm)",
            align: "left",
            headerColor: "#fff"
        });
        headers.push({
            label: "Weight (%)",
            align: "left",
            headerColor: "#fff"
        });
    }

    if (scanResult?.kernel_details?.consider_area) {
        headers.push({
            label: "Area (mm2)",
            align: "left",
            headerColor: "#fff"
        });
    }

    if (scanResult?.kernel_details?.consider_length) {
        headers.push({
            label: "Length (mm)",
            align: "left",
            headerColor: "#fff"
        });
    }
    headers.push({
        label: "Total Mistakes",
        align: "left",
        headerColor: "#fff"
    });
    headers.push({
        label: "% Accuracy",
        align: "left",
        headerColor: "#fff"
    });
    const firstRow = [];
    firstRow.push("");
    firstRow.push(scanResult?.kernel_details?.beautify?.translations?.total_kernel_count);

    if (scanResult?.kernel_details?.overall_score_grading) {
        firstRow.push(scanResult?.kernel_details?.overall_score_grading);
    } else {

    }
    firstRow.push(scanResult?.kernel_details?.total_kernel_count);
    firstRow.push("100%");
    if (parseFloat(scanResult?.kernel_details?.total_kernel_weight) > 0) {
        firstRow.push(parseFloat(scanResult?.scan_metadata?.weight) > 0 ? parseFloat(scanResult?.scan_metadata?.weight).toFixed(2) : parseFloat(scanResult?.kernel_details?.total_kernel_weight).toFixed(2))
        firstRow.push("100%");
    }
    if (scanResult?.kernel_details?.consider_area) {
        firstRow.push("")
    }
    if (scanResult?.kernel_details?.consider_length) {
        firstRow.push("")
    }
    rows.push(firstRow);

    let totalCount = 0;
    let accuracy;
    scanResult?.kernel_details?.classes.forEach((key, index) => {
        let row = [];
        row.push({
            cellType: 'box',
            color: scanResult?.processed_image?.encoding_colors[key],
        });
        row.push(scanResult?.kernel_details?.beautify?.translations?.kernel_counts[key] + " (" + scanResult?.processed_image?.encodings[key] + ")");

        if (scanResult?.kernel_details?.overall_score_grading) {
            row.push(scanResult?.kernel_details?.grading[key]);
        } else {

        }
        row.push(Number(scanResult?.kernel_details?.kernel_counts[key]));
        row.push(` ${Number(scanResult?.kernel_details?.kernel_counts[key] / scanResult?.kernel_details?.total_kernel_count * 100).toFixed(2)}%`);
        if (parseFloat(scanResult?.kernel_details?.total_kernel_weight) > 0) {
            let weightKey = scanResult?.kernel_details?.kernel_weights ? scanResult?.kernel_details?.kernel_weights : scanResult?.kernel_details?.kernel_weight
            if (parseFloat(scanResult?.scan_metadata?.weight) === 0) {
                row.push(Number(weightKey[key]).toFixed(2))
                row.push(Number(weightKey[key] / scanResult?.kernel_details?.total_kernel_weight * 100).toFixed(2) + "%")
            } else {
                const weightedPercentageOfTotal = Number(weightKey[key] / scanResult?.kernel_details?.total_kernel_weight * 100)
                const finalWeightFromSampleWeight = Number(weightedPercentageOfTotal * parseFloat(scanResult?.scan_metadata?.weight ? scanResult?.scan_metadata?.weight : scanResult?.kernel_details?.total_kernel_weight) / 100)
                row.push(Number(finalWeightFromSampleWeight).toFixed(2))
                row.push(Number(weightKey[key] / scanResult?.kernel_details?.total_kernel_weight * 100).toFixed(2) + "%")
            }
        }

        if (scanResult?.kernel_details?.consider_area) {
            row.push(Number(scanResult?.kernel_details?.areas[key]).toFixed(2));
        }

        if (scanResult?.kernel_details?.consider_length) {
            row.push(Number(scanResult?.kernel_details?.kernel_lengths[key]).toFixed(2));
        }
        const filteredData = filteredArray && filteredArray.filter((item) => item.originalClassName === key);
        const uniqueCombinations = new Map();
        filteredData && filteredData.forEach((obj) => {
            const combination = `${obj.index}-${obj.originalClassName}`;
            if (!uniqueCombinations.has(combination) || obj.timeStamp > uniqueCombinations.get(combination).timeStamp) {
                uniqueCombinations.set(combination, obj);
            }
        });
        const filteredKernel = Array.from(uniqueCombinations.values()).filter(obj => obj.originalClassName !== obj.changedClassName);
        const count = filteredKernel ? filteredKernel.length : 0;
        totalCount += count; // Increment the count
        if (filteredData) {
            row.push(Number(count));
        } else {
            row.push(0);
        }
        const numerator = Number(scanResult?.kernel_details?.kernel_counts[key]) - count;
        const denominator = Number(scanResult?.kernel_details?.kernel_counts[key]);
        accuracy = denominator !== 0 ? ((numerator / denominator) * 100).toFixed(2) + "%" : "0%";
        if (accuracy) {
            row.push(accuracy);
        }
        rows.push(row);
    });

    const middleRows = [];
    middleRows.push("");
    middleRows.push("Total");
    if (scanResult?.kernel_details?.overall_score_grading) {
        middleRows.push('');
    } else {
        middleRows.push('');
        middleRows.push("");
    }
    if (parseFloat(scanResult?.kernel_details?.total_kernel_weight) > 0) {
        middleRows.push('');
        middleRows.push("");
    }
    if (scanResult?.kernel_details?.consider_area) {
        middleRows.push("")
    }
    if (scanResult?.kernel_details?.consider_length) {
        middleRows.push("")
    }
    if (filteredArray) {
        middleRows.push(totalCount)
    }
    if (accuracy) {
        middleRows.push(((Number(scanResult?.kernel_details?.total_kernel_count) - Number(totalCount)) / Number(scanResult?.kernel_details?.total_kernel_count) * 100).toFixed(2) + "%");
    }
    rows.push(middleRows);
    const lastRow = [];
    lastRow.push("");
    lastRow.push("Moisture");
    if (scanResult?.scan_metadata) {
        lastRow.push(scanResult?.scan_metadata?.moisture ? `${scanResult.scan_metadata.moisture}%${scanResult.scan_metadata.moisture_weight
            ? ` (${scanResult.scan_metadata.moisture_weight}gm)`
            : ""
            }` : 'NA');
    }
    rows.push(lastRow);

    const table = {
        title: "",
        subtitle: "",
        headers: headers,
        rows: rows
    };
    const exportToCSV = () => {
        const csvRows = [];
        const firstTableHeaderRow = [
            'User Name',
            'Email/Phone Number',
            'ScanID',
            'Sub ScanID',
            'Grain Type',
            'Machine',
            'Date',
            'Phone/Device Name',
            'Location',
        ];
        csvRows.push(firstTableHeaderRow.join(','));

        const firstTableRow = [
            `${userDetail?.userInfo?.first_name} ${userDetail?.userInfo?.last_name}`,
            userDetail?.userInfo?.email && userDetail?.userInfo?.phone_number
                ? `${userDetail?.userInfo?.email} / ${userDetail?.userInfo?.phone_number}`
                : userDetail?.userInfo?.email || userDetail?.userInfo?.phone_number,
            scanID,
            subScanID,
            `${scanDetails?.crop_details?.crop} - ${scanDetails?.crop_details?.variety}`,
            `${scanDetails?.machine_details?.machine_name} (${scanDetails?.machine_details?.machine_code})`,
            `"${moment(scanDetails?.scan_details[0]?.scan_date).format(('DD MMM, YYYY hh:mm A'))}"`,
            `${scanDetails?.scan_result?.scan_result?.scan_metadata?.device_info}`,
            `"${address?.display_name}"`,
        ];
        csvRows.push(firstTableRow.join(','));

        csvRows.push('');
        csvRows.push('');
        const headerRow = table.headers.slice(1).map((column) => column.label);
        csvRows.push(headerRow.join(','));

        table.rows.forEach((row) => {
            const csvRow = row.slice(1).map((cell) => {
                const cellValue = cell.toString().replace(/"/g, '""');
                return `"${cellValue}"`;
            });
            csvRows.push(csvRow.join(','));
        });

        const csvData = csvRows.join('\n');
        const link = document.createElement('a');
        link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
        link.target = '_blank';
        link.download = `${scanID} _ ${subScanID}.csv`;
        link.click();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box style={style}>
                <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                    <Typography variant="h6" component="div" id="modal-modal-title" style={{ marginLeft: '20px' }}>
                        {title}
                    </Typography>
                    <div style={{ marginLeft: 'auto' }}>
                        <Button variant="contained" onClick={exportToCSV}>Export CSV</Button>
                        <IconButton aria-label="close" onClick={onClose} style={{ marginLeft: '45px' }}>
                            <ClearIcon />
                        </IconButton>
                    </div>
                </div>
                <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead style={{ backgroundColor: 'aliceblue', textAlign: "left" }}>
                                <TableRow>
                                    <TableCell>user Name</TableCell>
                                    <TableCell>Email/phoneNumber</TableCell>
                                    <TableCell>ScanID</TableCell>
                                    <TableCell>Sub ScanID</TableCell>
                                    <TableCell>Grain Type</TableCell>
                                    <TableCell>Machine</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Phone/Device Name</TableCell>
                                    <TableCell>Location</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                    <TableCell align="left">
                                        {`${userDetail?.userInfo?.first_name} ${userDetail?.userInfo?.last_name}`}
                                    </TableCell>
                                    <TableCell align="left">
                                        {userDetail?.userInfo?.email && userDetail?.userInfo?.phone_number ? (
                                            <span>
                                                {userDetail?.userInfo?.email} / {userDetail?.userInfo?.phone_number}
                                            </span>
                                        ) : (
                                            <span>
                                                {userDetail?.userInfo?.email || userDetail?.userInfo?.phone_number}
                                            </span>
                                        )}
                                    </TableCell>
                                    <TableCell align="left">
                                        {scanID}
                                    </TableCell>
                                    <TableCell align="left">
                                        {subScanID}
                                    </TableCell>
                                    <TableCell align="left">
                                        {scanDetails?.crop_details?.crop} {scanDetails?.crop_details?.variety}
                                    </TableCell>
                                    <TableCell align="left">
                                        {scanDetails?.machine_details?.machine_name} ({scanDetails?.machine_details?.machine_code})
                                    </TableCell>
                                    <TableCell align="left">
                                        {moment(scanDetails?.scan_details[0]?.scan_date).format(('DD MMM, YYYY hh:mm A'))}
                                    </TableCell>
                                    <TableCell align="left">
                                        {scanDetails?.scan_result?.scan_result?.scan_metadata?.device_info}
                                    </TableCell>
                                    <TableCell align="left">
                                        {address?.display_name}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead style={{ backgroundColor: 'aliceblue', textAlign: "left" }}>
                                <TableRow>
                                    {table.headers.map((header, index) => (
                                        <TableCell key={index} align={header.align} >
                                            {header.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {table.rows.map((row, index) => (
                                    <TableRow key={index} style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                        {row.map((cell, cellIndex) => (
                                            <TableCell key={cellIndex} align="left" style={{
                                                paddingRight: '50px',
                                            }}>
                                                {cell && cell.cellType === 'box' ? (
                                                    <div style={{ backgroundColor: cell.color, width: '20px', height: '20px' }}></div>
                                                ) : (
                                                    cell
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Box>
        </Modal>
    );

}

export default TableComponent;
