import React, { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import ImageViewer from './imageViewer';
import CircularProgress from '@mui/material/CircularProgress';
import QrPopup from './qrPopup';

const CustomDrawer = ({ onClose, scanID, subScanID, sacnFiles, scanPDFDetails, isLoading }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleViewButtonClick = (index) => {
    setSelectedImageIndex(index);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDownload = async (fileUrl, filename) => {
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
  };

  const PDFView = () => {
    const pdfUrl = URL.createObjectURL(scanPDFDetails?.data);
    window.open(pdfUrl)
  };

  const downloadPdf = () => {
    const downloadUrl = window.URL.createObjectURL(new Blob([scanPDFDetails?.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `${scanID}_${subScanID}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return (
    <>
      <Box sx={{ width: '100%', padding: '30px' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '50px' }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <h2 style={{ marginTop: '0px', marginBottom: '15px' }}>Get Files</h2>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {scanPDFDetails && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                  <Button variant="contained" color="success" className="App-bg-color opacity-9" onClick={() => downloadPdf()}>
                    Download Scan PDF
                  </Button>
                  <Button variant="contained" color="success" className="opacity-9" onClick={() => PDFView()}>
                    View Scan PDF
                  </Button>
                </div>
              )}
              {sacnFiles?.[0] === null ? (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
                  <div>
                    <strong>There are No Scan Files To Show</strong>
                  </div>
                </div>
              ) : (
                sacnFiles &&
                sacnFiles.map((file, index) => (
                  <div key={index} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <Button variant="contained" color="secondary" className="App-bg-color opacity-9" onClick={() => handleDownload(file.file_url, file.file_name)}>
                      Download {file.file_name}
                    </Button>
                    {!file.file_name.endsWith('.json') && (
                      <Button variant="contained" color="success" className="opacity-9" onClick={() => handleViewButtonClick(index)}>
                        View {file.file_name}
                      </Button>
                    )}
                  </div>
                ))
              )}
              <Button variant="contained" color="secondary" className="App-bg-color opacity-9" onClick={handleOpenPopup}>
                Generate QR Code
              </Button>
              {isPopupOpen && <QrPopup title='QR Code' scanID={scanID} open={isPopupOpen} onClose={() => setPopupOpen(false)} QRCanvas />}
            </>
          )}
        </div>
        {isModalOpen && (
          <div style={{ height: "600px" }}>
            <ImageViewer
              open={isModalOpen}
              onClose={handleCloseModal}
              title="Image Viewer"
              imageUrl={sacnFiles[selectedImageIndex]?.file_url}
              imageAlt={sacnFiles[selectedImageIndex]?.file_name}
            />
          </div>
        )}
      </Box>
    </>
  );

};

export default CustomDrawer;
